import React from 'react';
import styled from 'styled-components'

const ButtonStyled = styled.div`
    background-color: #F8F8F8;
    border: ${props => props.border || "none"};
    border-radius: 6px;
    color: #2D373C;
    cursor: pointer;
    font-family: SemiBoldFont;
    font-size: 14px;
    margin: 10px 0;
    padding: 15px;
    width: 90%;

    @media only screen and (min-width: 1200px) {
        font-size: 18px;
    }
`;

const MaoriText = styled.p`
    font-family: SemiBoldFont;
    font-size: 16px;
`;

const EngText = styled.p`
    font-family: RegularFont;
    font-size: 12px;
`;


const ButtonChoice = ({ maoriText, engText, active, setButtonChoiceState }) => {
    return (

        <ButtonStyled
            border={active ? "2px solid #2D373C" : "none"}
            onClick={() => setButtonChoiceState([maoriText, engText])}
        >
            <MaoriText>{maoriText}</MaoriText>
            <EngText>{engText}</EngText>
        </ButtonStyled>

    )
}

export default ButtonChoice;