import React from 'react';
import styled from 'styled-components'

// background: ${props => props.activeBackground};
// color: ${props => props.activeColor};
//activeColor={active ? "#ffffff" : "#2D373C"}

const Container = styled.div`
    display: flex;
    background-color: #F8F8F8;
    border: none;
    border-radius: 6px;
    font-family: RegularFont;
    font-size: 18;
    margin: 10px 0;
    padding: 2px;
    text-align: left;
    width: 276px;

    @media only screen and (min-width: 768px) {
       width: 395px;
    }

    @media only screen and (min-width: 1200px) {
        
    }
`;

const RadioButton = styled.img`
cursor: pointer;
    margin: 0 20px;
`;

const Text = styled.p`
    padding-right: 10px; 
`;



const ButtonOption = ({ text, setBtState, btState, active }) => {
    return (
        <Container onClick={() => setBtState(btState)} >
            {
                !active ?
                    <RadioButton src="/svg/radio-button.svg" alt={text} />
                    :
                    <RadioButton src="/svg/radio-button-active.svg" alt={text} />
            }
            <Text>{text}</Text>
        </Container>
    )
}

export default ButtonOption;

