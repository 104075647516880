import React from 'react';
import WaiataTextGroup from './WaiataTextGroup'

const LyricThree = () => {

    return (
        <div>
            <WaiataTextGroup
                textMaori="Te aroha"
                textEng="Love"
            />
            <WaiataTextGroup
                textMaori="Te whakapono"
                textEng="Faith"
            />
            <WaiataTextGroup
                textMaori="Me te rangimārie"
                textEng="and Peace"
            />
            <WaiataTextGroup
                textMaori="Tātou tātou e"
                textEng="we are united"
            />

        </div>

    )
}

export default LyricThree;