import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';

import Button from './Button'

const ExportToPDFButton = ({ toPrint, fileName }) => {

    const ORIGIN = window.location.origin

    return (
        <PDFDownloadLink document={
            toPrint
        } fileName={fileName}>
            {({ blob, url, loading, error }) =>
                loading ? 'Loading document...'
                    :
                    <div onClick={() => window.location.replace(ORIGIN)}>
                        <Button text="Export to PDF" />
                    </div>

            }
        </PDFDownloadLink>
    )
}

export default ExportToPDFButton;