import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import styled from 'styled-components'

const GreyBackground = styled.div`
    background-color: #F0F0F0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ContentOutContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    justify-content: center;
`;

const ContentInContainer = styled.div`
    text-align: center;

    @media only screen and (min-width: 768px) {
        width: 600px;
    }
`;

const Framewrok = styled.img`
    margin: 100px 0 30px 0 ;
    width: 80%;

    @media only screen and (min-width: 768px) {
        margin: 120px 0 50px 0 ;
        width: 600px;
    }
`;

const Text = styled.p`
    margin-top: -15px;
`;

const Title = styled.h1`
    margin-top: 50px;
    font-size: 26px;

    @media only screen and (min-width: 768px) {
        font-size: 36px;
    }
`;

const TextContainer = styled.div`
    margin: 20px 20px 60px 20px;
`;

const TextValues = styled.p`
    font-size: 12px;

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }
`;

const Icon = styled.img`
    margin-bottom: 20px;
    height:300px;
`;

const Logo = styled.img`
    width: 280px;
    margin-bottom: 60px;
`;


const Framework = () => {

    useEffect(() => {
        Analytics.record({
            name: "Framework page visit"
        })
    }, [])

    return (
        <div>
            <GreyBackground>
                <Framewrok src="/svg/framewrok-hero.svg" />
            </GreyBackground>
            <ContentOutContainer>
                <ContentInContainer>

                    <Title>Te Kākano Framework</Title>
                    {/* <Text>Our values framework</Text> */}

                    <TextContainer>
                        <Text>Te Pae Tawhiti</Text>
                        <Text>To enable Whānau, Hapū and Iwi to grow and thrive in the financial ecosystem.</Text>
                        <Text>The three pou of Te Kākano are:</Text>
                        <Text>• He Tāngata – Growing the cultural confidence of our people</Text>
                        <Text>• He Whānau – Growing the financial confidence of our communities</Text>
                        <Text>• Whai Rawa – Through our relationships with Māori and Iwi, we will financially grow and culturally thrive an inclusive Aotearoa</Text>
                    </TextContainer>

                    <Logo src="/img/logo-together.png" alt="Westpac together" />

                    <div>
                    <Icon src="/img/Helpful.png" />
                    </div>
                    <div>
                    <Icon src="/img/Ethical.png" />
                    </div>
                    <div>
                    <Icon src="/img/LeadingChange.png" />
                    </div>
                    <div>
                    <Icon src="/img/Performing.png" />
                    </div>
                    <div>
                    <Icon src="/img/Simple.png" />
                    </div>

                </ContentInContainer>
            </ContentOutContainer>
        </div>
    )
}

export default Framework;