

export const mountain = (input) => {
    return [
        `Ko ${input || "..."} te maunga`,
        `My mountain is ${input || "..."}`
    ]
}

export const river = (input) => {
    return [
        `Ko ${input || "..."} te awa`,
        `My river is ${input || "..."}`
    ]
}

export const lake = (input) => {
    return [
        `Ko ${input || "..."} te roto`,
        `My lake is ${input || "..."}`
    ]
}

export const sea = (input) => {
    return [
        `Ko ${input || "..."} te moana`,
        `My sea is ${input || "..."}`
    ]
}


export const marae = (input) => {
    return [
        `Ko ${input || "..."} taku marae`,
        `My marae is ${input || "..."}`
    ]
}




export const canoe = (input) => {
    return [
        `Ko ${input || "..."} taku waka`,
        `My canoe is ${input || "..."}`
    ]
}

export const tribe = (input) => {
    return [
        `Ko ${input || "..."} taku iwi`,
        `My tribe is ${input || "..."}`
    ]
}

export const subTribe = (input) => {
    return [
        `Ko ${input || "..."} taku hapū`,
        `my sub-tribe is ${input || "..."}`
    ]
}

export const iAmFrom = (input) => {
    return [
        `Nō ${input || "..."} ahau`,
        `I am from ${input || "..."}`
    ]
}

export const family = (input) => {
    return [
        `Ko ${input || "..."} taku whānau`,
        `My family is ${input || "..."}`
    ]
}



export const name = (input) => {
    return [
        `Ko ${input || "..."} taku ingoa`,
        `My name is ${input || "..."}`
    ]
}

export const iWasBorn = (input) => {
    return [
        `I whānau mai ahau i ${input || "..."}`,
        `I was born in ${input || "..."}`
    ]
}

export const iLive = (input) => {
    return [
        `E noho ana ahau i ${input || "..."}`,
        `I live in ${input || "..."}`
    ]
}


