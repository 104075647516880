import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import styled from 'styled-components'
import Title from '../components/Title'
import SubTitle from '../components/SubTitle'
import Image from '../components/Image'



const ContentOutContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ContentInContainer = styled.div`
    margin-top: 40px;
    text-align: center;
`;


const TextContainer = styled.div`
    margin: 20px 20px 80px 20px;

    @media only screen and (min-width: 650px) {
        width: 600px;
    }
`;

const TextValues = styled.p`
    font-size: 12px;

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }
`;

const About = () => {

    useEffect(() => {
        Analytics.record({
            name: "About page visit"
        })
    }, [])

    return (
        <div>
            <Image image="/img/about.jpg" />
            {/* <GreyBackground>
                <Framewrok src="/svg/framewrok-hero.svg" />
            </GreyBackground> */}
            <ContentOutContainer>
                <ContentInContainer>
                    <Title title="Mō tēnei rauemi" />
                    <SubTitle subtitle="About this resource" />

                    <TextContainer>
                        <TextValues>
                            Whitikina mai e te rā ki runga i a mātou<br />
                            Ngā pua e puawai nei<br />
                            E tipu nei, e rea nui, e whanake nei<br />
                            Huami e, hui e, tāiki ē!!<br />
                            <br />
                            The sun shines upon us all<br />
                            The young seedlings that are cared for, keep growing<br />
                            Let this be reality
                        </TextValues>
                        <TextValues>
                            Te Kākano means “the seed”, and it is our Westpac framework to strengthen our relationships with
                            Māori and iwi. One of our pou - strategic pillars - is to grow the cultural confidence of our people.
                            To do this, we must first remove the fear of engagement, and replace it with confidence that you know
                            what to do and say in different situations.<br /><br />

                            To grow in confidence, the first step is to get started; you don’t have to be great to get started,
                            but you do have to get started to be great!<br /><br />

                            The next is to practise, practise alone, practise with someone, practise in a group… you get the message: practise.<br />

                            The third, give it a go for real, and you are not alone - we are here to help you.<br /><br />

                            Karawhiua! Give it heaps, give it all you got.
                        </TextValues>
                    </TextContainer>
                </ContentInContainer>
            </ContentOutContainer>
        </div>
    )
}

export default About;