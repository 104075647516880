import React from 'react';
import KarakiaTextGroup from './KarakiaTextGroup'

const LyricFive = () => {

    return (
        <div>
            <KarakiaTextGroup
                textMaori="Mauri oho"
                textEng="Life force awaken"
            />
            <KarakiaTextGroup
                textMaori="Mauri tū"
                textEng="Life force stand tall"
            />
            <KarakiaTextGroup
                textMaori="Mauri ora, ki a tātou"
                textEng="Life force all wellness, good health for all"
            />
            <KarakiaTextGroup
                textMaori="Haumi e, Hui e, Tāiki e!"
                textEng="Join together, unite, the group is ready to progress for the purpose of coming together"
            />


        </div>
    )
}

export default LyricFive;