import React from 'react';
import KarakiaTextGroup from './KarakiaTextGroup'

const LyricFour = () => {

    return (
        <div>
            <KarakiaTextGroup
                textMaori="Kia tau iho "
                textEng="Let the strength"
            />
            <KarakiaTextGroup
                textMaori="Te tauwhirotanga"
                textEng="and serenity"
            />
            <KarakiaTextGroup
                textMaori="O te wāhi ngaro"
                textEng="of our ancestors"
            />
            <KarakiaTextGroup
                textMaori="E pai ai te nohotahi"
                textEng="guide us as we gather"
            />
            <KarakiaTextGroup
                textMaori="Ā tinana, wairua hoki"
                textEng="in body and spirit"
            />
            <KarakiaTextGroup
                textMaori="Whakauenuku ake"
                textEng="Holdfast to"
            />
            <KarakiaTextGroup
                textMaori="Te āio mō te katoa"
                textEng="Peace for all"
            />
            <KarakiaTextGroup
                textMaori="Kia whakamaua kia tīna"
                textEng="let it be realised"
            />
            <KarakiaTextGroup
                textMaori="Hui e Tāiki e"
                textEng="for us all"
            />

        </div>
    )
}

export default LyricFour;