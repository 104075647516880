import React from 'react'
import styled from 'styled-components'
import Title from './Title';

const PopUpContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: center;
    align-items: center;

    background-color: #2D373C;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 102;
`;

const Card = styled.div`
    background-color: #FFFFFF;
    border-radius: 15px;
    width: 325px;

    @media only screen and (min-width: 768px) {
        width: 367px;
    }
`;

const ContentContainer = styled.div`
    margin: 50px ;
    text-align: center;
`;

const Loading = styled.img`
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;

    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
`;



const PopUpLoading = ({ word }) => {
    return (
        <PopUpContainer>
            <Card>
                <ContentContainer>
                    <Loading src='/svg/loading.svg' />
                    <Title title="Ka rawe! Awesome!" />
                    <p>Your personal {word} is being created now.</p>
                </ContentContainer>
            </Card>
        </PopUpContainer>
    )
}


export default PopUpLoading