import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;
`;

const Mo = ({
    setInputState,
    inputValue
}) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='kaha'
                engText="strength"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "kaha" ? true : false}
            />
            <ButtonChoice
                maoriText="māia"
                engText="willingness"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "māia" ? true : false}
            />
            <ButtonChoice
                maoriText="piri pono"
                engText="loyalty"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "piri pono" ? true : false}
            />
            <ButtonChoice
                maoriText="manawanui"
                engText="dedication"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "manawanui" ? true : false}
            />
            <ButtonChoice
                maoriText="kai ngākau "
                engText="interest"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "kai ngākau " ? true : false}
            />
            <ButtonChoice
                maoriText="ngana"
                engText="resilience"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "ngana" ? true : false}
            />
        </ButtonContainer>
    )
}

export default Mo;