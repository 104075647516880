import React from 'react';
import styled from 'styled-components'
import TextToPrint from '../TextToPrint'
import TextToPrintThreeChildren from '../TextToPrintThreeChildren'
import InvertColor from '../InvertColor';

import {
    sMother,
    sFather,
    sPartner,
    sChild,
    sTwoChildren,
    sMaternalGrandFather,
    sMaternalGrandMother,
    sPaternalGrandFather,
    sPaternalGrandMother,
} from './WhakapapaSentences'

const Container = styled.div`
    margin-top: -50px;
    text-align: center;
    padding: 0 10px 50px 10px;
`;

const IsMaoriPepehaToPrintUi = ({
    mother,
    father,
    partner,
    child,
    twoChildren,
    threeChildren,
    maternalGrandFather,
    maternalGrandMother,
    paternalGrandFather,
    paternalGrandMother,
    setToPrintTheme,
    toPrintTheme,

    childrenNumber
}) => {

    console.log(partner)

    return (
        <Container>
            {
                maternalGrandFather !== "" &&
                <TextToPrint
                    textMaori={sMaternalGrandFather(maternalGrandFather)[0]}
                    textEng={sMaternalGrandFather(maternalGrandFather)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }
            {
                maternalGrandMother !== "" &&
                <TextToPrint
                    textMaori={sMaternalGrandMother(maternalGrandMother)[0]}
                    textEng={sMaternalGrandMother(maternalGrandMother)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                paternalGrandFather !== "" &&
                <TextToPrint
                    textMaori={sPaternalGrandFather(paternalGrandFather)[0]}
                    textEng={sPaternalGrandFather(paternalGrandFather)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }
            {
                paternalGrandMother !== "" &&
                <TextToPrint
                    textMaori={sPaternalGrandMother(paternalGrandMother)[0]}
                    textEng={sPaternalGrandMother(paternalGrandMother)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }
            {
                mother !== "" &&
                <TextToPrint
                    textMaori={sMother(mother)[0]}
                    textEng={sMother(mother)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }
            {
                father !== "" &&
                <TextToPrint
                    textMaori={sFather(father)[0]}
                    textEng={sFather(father)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }
            {
                partner !== "" &&
                <TextToPrint
                    textMaori={sPartner(partner)[0]}
                    textEng={sPartner(partner)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                child !== "" && childrenNumber === "One child" &&
                <TextToPrint
                    textMaori={sChild(child)[0]}
                    textEng={sChild(child)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                twoChildren[0].name !== "" && childrenNumber === "Two children" &&
                <TextToPrint
                    textMaori={sTwoChildren(twoChildren)[0]}
                    textEng={sTwoChildren(twoChildren)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                threeChildren[0].name !== "" && childrenNumber === "Three or more children" &&
                <TextToPrintThreeChildren
                    inputValueThreeChildren={threeChildren}
                    toPrintTheme={toPrintTheme}
                />
            }

            <InvertColor setToPrintTheme={setToPrintTheme} toPrintTheme={toPrintTheme} />
        </Container>
    )
}

export default IsMaoriPepehaToPrintUi;