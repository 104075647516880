import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from "styled-components"
import ButtonMenu from './ButtonMenu';

const MenuContainer = styled.div`
    margin: 10px;
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    justify-content: center;
    flex-direction: row;  
`;

const MenuOpenContainer = styled.div`
    background-color: #2D373C;
    padding-top: 80px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: -100;
    height: 100vh;

    

    @media only screen and (max-width: 767px) {
        right: 0; 
    }

    @media only screen and (min-width: 768px) {
        width: 345px;
        margin-left: -290px;
    }
`;

const LinksContainer = styled.div`
    margin-bottom: 25px;
    width: 50%;

    @media only screen and (max-height: 668px) {
        width: 100px; 
    }
`;

const MenuImages = styled.img`
    cursor: pointer;
`;


const Menu = () => {
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    let location = useLocation()
    let path = location.pathname

    return (
        <MenuContainer>
            {
                !menuIsOpen ?
                    <>
                        {path === "/" ?

                            <MenuImages onClick={() => setMenuIsOpen(!menuIsOpen)} src='/svg/menu-white.svg' alt="" />
                            :
                            <MenuImages onClick={() => setMenuIsOpen(!menuIsOpen)} src='/svg/menu.svg' alt="" />
                        }
                    </>
                    :
                    <MenuImages onClick={() => setMenuIsOpen(!menuIsOpen)} src='/svg/menu-close.svg' alt="" />

            }

            {
                menuIsOpen &&

                <MenuOpenContainer>
                    <Flex>
                        <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <ButtonMenu
                                link="/"
                                image="/svg/menu-home-icon-circle.svg"
                                maoriText="Kāinga"
                                engText="Home"
                            />
                        </LinksContainer>
                        <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <ButtonMenu
                                link="/pepeha/0/0"
                                image="/svg/menu-pepeha-icon-circle.svg"
                                maoriText="Pepeha"
                                engText="Provenance"
                            />
                        </LinksContainer>
                        <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <ButtonMenu
                                link="/mihimihi/0/0"
                                image="/svg/menu-mihi-icon-circle.svg"
                                maoriText="Mihi"
                                engText="Greeting"
                            />
                        </LinksContainer>
                        <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <ButtonMenu
                                link="/whakapapa/0/0"
                                image="/svg/menu-whakapapa-icon-circle.svg"
                                maoriText="Whakapapa"
                                engText="Genealogy"
                            />
                        </LinksContainer>
                        <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <ButtonMenu
                                link="/akona/0"
                                image="/svg/menu-pronounciation-icon-circle.svg"
                                maoriText="Whakahua tika"
                                engText="Pronunciation"
                            />
                        </LinksContainer>
                        <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <ButtonMenu
                                link="/karakia/0"
                                image="/svg/menu-karakia-icon-circle.svg"
                                maoriText="Karakia"
                                engText="Blessings"
                            />
                        </LinksContainer>
                        <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <ButtonMenu
                                link="/waiata/0"
                                image="/svg/menu-waiata-icon-circle.svg"
                                maoriText="Waiata"
                                engText="Songs"
                            />
                        </LinksContainer>
                        <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <ButtonMenu
                                link="/glossary"
                                image="/svg/menu-glossary-icon-circle.svg"
                                maoriText="Kupu taka"
                                engText="Glossary"
                            />
                        </LinksContainer>
                        <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <ButtonMenu
                                link="/framework"
                                image="/svg/menu-values-icon-circle.svg"
                                maoriText="Te Kākano"
                                engText="Westpac Values"
                            />
                        </LinksContainer>
                        <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <ButtonMenu
                                link="/about"
                                image="/svg/menu-about-icon-circle.svg"
                                maoriText="Mō tēnei rauemi"
                                engText="About this resource"
                            />
                        </LinksContainer>
                    </Flex>
                </MenuOpenContainer>
            }
        </MenuContainer >
    )
}

export default Menu;