import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components'
import InputQuestion from '../InputQuestion';
import SectionToPrint from '../SectionToPrint'
import IsMaoriPepehaToPrintUi from './IsMaoriPepehaToPrintUi';
import IsMaoriPepehaToPrint from './IsMaoriPepehaToPrint';
import ExportToPDFButton from '../ExportToPDFButton';
import PopUpLoading from '../PopUpLoading';
import Button from '../Button';
import BasicAdvanced from '../BasicAdvanced';
import {
    mountain,
    river,
    lake,
    sea,
    marae,
    canoe,
    tribe,
    subTribe,
    iAmFrom,
    family,
    name,
    iWasBorn,
    iLive

} from './IsMaoriPepehaSentences'

const ContainerToPrint = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ToPrintContainer = styled.div`
    width: 90%;

    @media only screen and (min-width: 650px) {
        width: 600px;
    }
`;

const ContainerOut = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 90vh;
`;

const ContainerIn = styled.div`
    width: 100%

    @media only screen and (min-width: 650px) {
        width: 400px;
    }
`;



const IsMaori = (
    {
        inputValueMountain,
        setInputStateMountain,
        inputValueRiver,
        setInputStateRiver,
        inputValueLake,
        setInputStateLake,
        inputValueSea,
        setInputValueSea,
        inputValueMarae,
        setInputValueMarae,
        inputValueCanoe,
        setInputValueCanoe,
        inputValueTribe,
        setInputValueTribe,
        inputValueSubTribe,
        setInputValueSubTribe,
        inputValueWhereFrom,
        setInputValueWhereFrom,
        inputValueWhanau,
        setInputValueWhanau,
        inputValueName,
        setInputValueName,
        inputValueWhereBorn,
        setInputValueWhereBorn,
        inputValueWhereLiving,
        setInputValueWhereLiving
    }
) => {
    let { flow } = useParams()
    let history = useHistory()
    const [toPrintTheme, setToPrintTheme] = useState(true)
    const [isBasic, setIsBasic] = useState("error")
    const clearHistory = function() {
        setInputStateMountain("")
        setInputStateRiver("")
        setInputStateLake("")
        setInputValueSea("")
        setInputValueMarae("")
        setInputValueCanoe("")
        setInputValueTribe("")
        setInputValueSubTribe("")
        setInputValueWhereFrom("")
        setInputValueWhanau("")
        setInputValueName("")
        setInputValueWhereBorn("")
        setInputValueWhereLiving("")
    }
    return (
        <div>
            <ContainerToPrint>
                {
                    flow === "15" &&
                    <ToPrintContainer>
                        <SectionToPrint
                            imgSrc="/svg/pepeha-red.svg"
                            title="Pepeha"
                            toPrintTheme={toPrintTheme}
                            toPrintUI={
                                <IsMaoriPepehaToPrintUi
                                    inputValueMountain={inputValueMountain}
                                    inputValueRiver={inputValueRiver}
                                    inputValueLake={inputValueLake}
                                    inputValueSea={inputValueSea}
                                    inputValueMarae={inputValueMarae}
                                    inputValueCanoe={inputValueCanoe}
                                    inputValueTribe={inputValueTribe}
                                    inputValueSubTribe={inputValueSubTribe}
                                    inputValueWhereFrom={inputValueWhereFrom}
                                    inputValueWhanau={inputValueWhanau}
                                    inputValueName={inputValueName}
                                    inputValueWhereBorn={inputValueWhereBorn}
                                    inputValueWhereLiving={inputValueWhereLiving}
                                    setToPrintTheme={setToPrintTheme}
                                    toPrintTheme={toPrintTheme}
                                />
                            }
                        />
                        <ExportToPDFButton
                            toPrint={
                                <IsMaoriPepehaToPrint
                                    inputValueMountain={inputValueMountain}
                                    inputValueRiver={inputValueRiver}
                                    inputValueLake={inputValueLake}
                                    inputValueSea={inputValueSea}
                                    inputValueMarae={inputValueMarae}
                                    inputValueCanoe={inputValueCanoe}
                                    inputValueTribe={inputValueTribe}
                                    inputValueSubTribe={inputValueSubTribe}
                                    inputValueWhereFrom={inputValueWhereFrom}
                                    inputValueWhanau={inputValueWhanau}
                                    inputValueName={inputValueName}
                                    inputValueWhereBorn={inputValueWhereBorn}
                                    inputValueWhereLiving={inputValueWhereLiving}
                                    toPrintTheme={toPrintTheme}
                                />
                            }
                            fileName="Pepeha.pdf"
                        />
                        <Link to="/pepeha/maori/0.5" onClick={clearHistory}>
                            <Button
                                text="Start over"
                                ArgBackgroundColor="white"
                                ArgBorder="1px solid #D5002B"
                                ArgColor="#D5002B"
                                ArgHoverBackgroundColor="#F0F0F0"
                            />
                        </Link>
                    </ToPrintContainer>
                }
            </ContainerToPrint>
            {
                flow < 15 &&
                <ContainerOut>
                    <ContainerIn>

                        {
                            flow === "0.5" &&
                            <BasicAdvanced
                                setState={setIsBasic}
                                state={isBasic}
                                image="/svg/mountain.svg"
                                basic="Basic pepeha"
                                advanced="Advanced pepeha"
                                path="/pepeha/maori/1"
                                title="Create my pepeha"
                                onClick={clearHistory}
                            />
                        }

                        {
                            flow === "1" &&
                            <InputQuestion
                                path="/pepeha/maori/2"
                                inputValue={inputValueMountain}
                                setInputState={setInputStateMountain}
                                imgSrc="/svg/mountain.svg"
                                title={mountain(inputValueMountain)[0]}
                                text={mountain(inputValueMountain)[1]}
                                placeholder="Mountain"
                            />
                        }
                        {
                            flow === "2" &&
                            <InputQuestion
                                path="/pepeha/maori/3"
                                inputValue={inputValueLake}
                                setInputState={setInputStateLake}
                                imgSrc="/svg/lake.svg"
                                title={lake(inputValueLake)[0]}
                                text={lake(inputValueLake)[1]}
                                placeholder="Lake"
                            />
                        }
                        {
                            flow === "3" &&
                            <InputQuestion
                                path="/pepeha/maori/4"
                                inputValue={inputValueRiver}
                                setInputState={setInputStateRiver}
                                imgSrc="/svg/river.svg"
                                title={river(inputValueRiver)[0]}
                                text={river(inputValueRiver)[1]}
                                placeholder="River"
                            />
                        }
                        {
                            flow === "4" &&
                            <InputQuestion
                                path="/pepeha/maori/5"
                                inputValue={inputValueSea}
                                setInputState={setInputValueSea}
                                imgSrc="/svg/sea.svg"
                                title={sea(inputValueSea)[0]}
                                text={sea(inputValueSea)[1]}
                                placeholder="Sea name"
                            />
                        }
                        {
                            flow === "5" &&
                            <InputQuestion
                                path="/pepeha/maori/6"
                                inputValue={inputValueMarae}
                                setInputState={setInputValueMarae}
                                imgSrc="/svg/marae.svg"
                                title={marae(inputValueMarae)[0]}
                                text={marae(inputValueMarae)[1]}
                                placeholder="Marae name"
                            />
                        }
                        {
                            flow === "6" &&
                            <InputQuestion
                                path="/pepeha/maori/7"
                                inputValue={inputValueCanoe}
                                setInputState={setInputValueCanoe}
                                imgSrc="/svg/canoe.svg"
                                title={canoe(inputValueCanoe)[0]}
                                text={canoe(inputValueCanoe)[1]}
                                placeholder="Canoe name"
                            />
                        }
                        {
                            flow === "7" &&
                            <InputQuestion
                                path="/pepeha/maori/8"
                                inputValue={inputValueTribe}
                                setInputState={setInputValueTribe}
                                imgSrc="/svg/tribe.svg"
                                title={tribe(inputValueTribe)[0]}
                                text={tribe(inputValueTribe)[1]}
                                placeholder="Tribe name"
                            />
                        }
                        {
                            flow === "8" &&
                            <InputQuestion
                                path="/pepeha/maori/9"
                                inputValue={inputValueSubTribe}
                                setInputState={setInputValueSubTribe}
                                imgSrc="/svg/sub-tribe.svg"
                                title={subTribe(inputValueSubTribe)[0]}
                                text={subTribe(inputValueSubTribe)[1]}
                                placeholder="Sub-tribe name"
                            />
                        }
                        {
                            flow === "9" &&
                            <InputQuestion
                                path="/pepeha/maori/10"
                                inputValue={inputValueWhereFrom}
                                setInputState={setInputValueWhereFrom}
                                imgSrc="/svg/i-am-from.svg"
                                title={iAmFrom(inputValueWhereFrom)[0]}
                                text={iAmFrom(inputValueWhereFrom)[1]}
                                placeholder="Place name"
                            />
                        }
                        {
                            flow === "10" &&
                            <InputQuestion
                                path="/pepeha/maori/11"
                                inputValue={inputValueWhanau}
                                setInputState={setInputValueWhanau}
                                imgSrc="/svg/whanau.svg"
                                title={family(inputValueWhanau)[0]}
                                text={family(inputValueWhanau)[1]}
                                placeholder="Whānau name"
                            />
                        }
                        {
                            flow === "11" &&
                            <InputQuestion
                                path={isBasic === true ? "/pepeha/maori/14" : "/pepeha/maori/12"}
                                inputValue={inputValueName}
                                setInputState={setInputValueName}
                                imgSrc="/svg/name.svg"
                                title={name(inputValueName)[0]}
                                text={name(inputValueName)[1]}
                                placeholder="Your name"
                            />
                        }
                        {
                            flow === "12" && isBasic === false &&
                            <InputQuestion
                                path="/pepeha/maori/13"
                                inputValue={inputValueWhereBorn}
                                setInputState={setInputValueWhereBorn}
                                imgSrc="/svg/was-born.svg"
                                title={iWasBorn(inputValueWhereBorn)[0]}
                                text={iWasBorn(inputValueWhereBorn)[1]}
                                placeholder="Place name"
                            />
                        }
                        {
                            flow === "13" && isBasic === false &&
                            <InputQuestion
                                path="/pepeha/maori/14"
                                inputValue={inputValueWhereLiving}
                                setInputState={setInputValueWhereLiving}
                                imgSrc="/svg/i-live-in.svg"
                                title={iLive(inputValueWhereLiving)[0]}
                                text={iLive(inputValueWhereLiving)[1]}
                                placeholder="Place name"
                            />
                        }

                        {
                            flow === "14" &&

                            <div onClick={setTimeout(function () { history.push("/pepeha/maori/15") }, 3000)}>
                                <PopUpLoading word="pepeha" />
                            </div>
                        }

                    </ContainerIn>

                </ContainerOut>
            }


        </div>
    )
}

export default IsMaori;