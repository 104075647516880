import React from 'react';
import KarakiaTextGroup from './KarakiaTextGroup'

const LyricTwo = () => {

    return (
        <div>
            <KarakiaTextGroup
                textMaori="Ūhia mai rā"
                textEng="Bestow upon us"
            />
            <KarakiaTextGroup
                textMaori="Ko te wairua tapu"
                textEng="the divine spirit"
            />
            <KarakiaTextGroup
                textMaori="Hei pūeru mō te iwi"
                textEng="to cloak the people"
            />
            <KarakiaTextGroup
                textMaori="Me te aroha"
                textEng="and with the love"
            />
            <KarakiaTextGroup
                textMaori="O te runga rawa"
                textEng="of the higher being"
            />
            <KarakiaTextGroup
                textMaori="Ua noa nei"
                textEng="may it shower"
            />
            <KarakiaTextGroup
                textMaori="Ki te whenua"
                textEng="across the land"
            />
            <KarakiaTextGroup
                textMaori="Tōmairangi noa"
                textEng="and dew"
            />
            <KarakiaTextGroup
                textMaori="Ki ngā tāngata katoa"
                textEng="upon all people"
            />
            <KarakiaTextGroup
                textMaori="Haumi e hui e tāiki e"
                textEng="for us all"
            />

        </div>
    )
}

export default LyricTwo;