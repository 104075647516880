import React from 'react';
import styled from 'styled-components'
import ButtonTapPlay from '../ButtonTapPlay';
import SoundIcon from '../SoundIcon';

const OutContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 30px;
`;

const TextContainer = styled.div`
    @media only screen and (max-width: 768px) {
        margin: 0 20px;;
    }

    @media only screen and (min-width: 768px) {
        width: 380px;
    }
`;

const SubTitle = styled.p`
    font-size: 20px;
    margin: 50px 0 30px 0;
`;

const BtContainer = styled.div`
    margin-bottom: 30px;
`;

const S3_URL = "https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/whakahua/Syllables/"

const Syllables = () => {

    return (
        <OutContainer>
            <div>
                <TextContainer>
                    <p>
                        Every syllable ends with a vowel. Click in the syllables to hear the correct pronunciation.
                    </p>
                </TextContainer>

                <SubTitle>Syllables <span><SoundIcon /></span></SubTitle>
                <BtContainer>
                    <ButtonTapPlay text="HA" url={`${S3_URL}ha.mp3`} />
                    <ButtonTapPlay text="HE" url={`${S3_URL}he.mp3`} />
                    <ButtonTapPlay text="HI" url={`${S3_URL}hi.mp3`} />
                    <ButtonTapPlay text="HO" url={`${S3_URL}ho.mp3`} />
                    <ButtonTapPlay text="HU" url={`${S3_URL}hu.mp3`} />
                </BtContainer>

                <BtContainer>
                    <ButtonTapPlay text="KA" url={`${S3_URL}ka.mp3`} />
                    <ButtonTapPlay text="KE" url={`${S3_URL}ke.mp3`} />
                    <ButtonTapPlay text="KI" url={`${S3_URL}ki.mp3`} />
                    <ButtonTapPlay text="KO" url={`${S3_URL}ko.mp3`} />
                    <ButtonTapPlay text="KU" url={`${S3_URL}ku.mp3`} />
                </BtContainer>

                <BtContainer>
                    <ButtonTapPlay text="MA" url={`${S3_URL}ma.mp3`} />
                    <ButtonTapPlay text="ME" url={`${S3_URL}me.mp3`} />
                    <ButtonTapPlay text="MI" url={`${S3_URL}mi.mp3`} />
                    <ButtonTapPlay text="MO" url={`${S3_URL}mo.mp3`} />
                    <ButtonTapPlay text="MU" url={`${S3_URL}mu.mp3`} />
                </BtContainer>

                <BtContainer>
                    <ButtonTapPlay text="NA" url={`${S3_URL}na.mp3`} />
                    <ButtonTapPlay text="NE" url={`${S3_URL}ne.mp3`} />
                    <ButtonTapPlay text="NI" url={`${S3_URL}ni.mp3`} />
                    <ButtonTapPlay text="NO" url={`${S3_URL}no.mp3`} />
                    <ButtonTapPlay text="NU" url={`${S3_URL}nu.mp3`} />
                </BtContainer>

                <BtContainer>
                    <ButtonTapPlay text="PA" url={`${S3_URL}pa.mp3`} />
                    <ButtonTapPlay text="PE" url={`${S3_URL}pe.mp3`} />
                    <ButtonTapPlay text="PI" url={`${S3_URL}pi.mp3`} />
                    <ButtonTapPlay text="PO" url={`${S3_URL}po.mp3`} />
                    <ButtonTapPlay text="PU" url={`${S3_URL}pu.mp3`} />
                </BtContainer>

                <BtContainer>
                    <ButtonTapPlay text="RA" url={`${S3_URL}ra.mp3`} />
                    <ButtonTapPlay text="RE" url={`${S3_URL}re.mp3`} />
                    <ButtonTapPlay text="RI" url={`${S3_URL}ri.mp3`} />
                    <ButtonTapPlay text="RO" url={`${S3_URL}ro.mp3`} />
                    <ButtonTapPlay text="RU" url={`${S3_URL}ru.mp3`} />
                </BtContainer>

                <BtContainer>
                    <ButtonTapPlay text="TA" url={`${S3_URL}ta.mp3`} />
                    <ButtonTapPlay text="TE" url={`${S3_URL}te.mp3`} />
                    <ButtonTapPlay text="TI" url={`${S3_URL}ti.mp3`} />
                    <ButtonTapPlay text="TO" url={`${S3_URL}to.mp3`} />
                    <ButtonTapPlay text="TU" url={`${S3_URL}tu.mp3`} />
                </BtContainer>

                <BtContainer>
                    <ButtonTapPlay text="WA" url={`${S3_URL}wa.mp3`} />
                    <ButtonTapPlay text="WE" url={`${S3_URL}we.mp3`} />
                    <ButtonTapPlay text="WI" url={`${S3_URL}wi.mp3`} />
                    <ButtonTapPlay text="WO" url={`${S3_URL}wo.mp3`} />
                    <ButtonTapPlay text="WU" url={`${S3_URL}wu.mp3`} />
                </BtContainer>

                <BtContainer>
                    <ButtonTapPlay text="NGA" url={`${S3_URL}nga.mp3`} />
                    <ButtonTapPlay text="NGE" url={`${S3_URL}nge.mp3`} />
                    <ButtonTapPlay text="NGI" url={`${S3_URL}ngi.mp3`} />
                    <ButtonTapPlay text="NGO" url={`${S3_URL}ngo.mp3`} />
                    <ButtonTapPlay text="NGU" url={`${S3_URL}ngu.mp3`} />
                </BtContainer>

                <BtContainer>
                    <ButtonTapPlay text="WHA" url={`${S3_URL}wha.mp3`} />
                    <ButtonTapPlay text="WHE" url={`${S3_URL}whe.mp3`} />
                    <ButtonTapPlay text="WHI" url={`${S3_URL}whi.mp3`} />
                    <ButtonTapPlay text="WHO" url={`${S3_URL}who.mp3`} />
                    <ButtonTapPlay text="WHU" url={`${S3_URL}whu.mp3`} />
                </BtContainer>
            </div>
        </OutContainer>
    )
}

export default Syllables;