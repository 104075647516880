import React from 'react';
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import ButtonChoiceB from '../ButtonChoiceB';

const OutContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
`;

const ButtonContainer = styled.div`

`;

const AkonaButtons = () => {
    const { view } = useParams()

    return (
        <OutContainer>
            <ButtonContainer>
                {
                    view !== "1" &&
                    <Link to="/akona/1">
                        <ButtonChoiceB
                            maoriText='Ngā oropuare'
                            engText="Vowels"
                        />
                    </Link>
                }

                {
                    view !== "2" &&
                    <Link to="/akona/2">
                        <ButtonChoiceB
                            maoriText='Ngā kūoro'
                            engText="Syllables"
                        />
                    </Link>
                }

                {
                    view !== "3" &&
                    <Link to="/akona/3">
                        <ButtonChoiceB
                            maoriText='Ngā orokati'
                            engText="Consonants"
                        />
                    </Link>
                }

                {
                    view !== "4" &&
                    <Link to="/akona/4">
                        <ButtonChoiceB
                            maoriText='Diphthongs'
                            engText="Ngā oro honohono"
                        />
                    </Link>
                }
            </ButtonContainer>
        </OutContainer>

    )
}

export default AkonaButtons;