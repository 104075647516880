import React from 'react';
import styled from 'styled-components'

const Error = styled.p`
    color: #D5002B;
    font-family: SemiBoldFont;
    font-size: 16px;
`;

const ErrorMessage = ({ text }) => <Error>{text}</Error>

export default ErrorMessage;

