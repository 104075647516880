import React from 'react';
import styled from 'styled-components'

const Container = styled.div`
    margin: 0 20px;
`;

const TextMaori = styled.p`
    color: #2F373B;
    font-family: MediumFont;
    font-size: 15px;

    @media only screen and (min-width: 768px) {
        font-size: 20px;;
    }
`;

const TextEng = styled.p`
    color: #2F373B;
    margin-top: -15px;
    font-size: 12px;

    @media only screen and (min-width: 768px) {
        font-size: 15px;
    }
`;

const TextTextGroup = ({ textMaori, textEng }) => {

    return (
        <Container>
            <TextMaori>{textMaori}</TextMaori>
            <TextEng>{textEng}</TextEng>
        </Container>
    )
}

export default TextTextGroup;