import React from 'react';
import styled from 'styled-components'

const InvertColorBt = styled.img`
    margin-top: 20px;
    cursor: pointer;
`;

const InvertColor = ({ toPrintTheme, setToPrintTheme }) => {
    return <InvertColorBt onClick={() => setToPrintTheme(!toPrintTheme)} src="/svg/invert-color.svg" />
}

export default InvertColor;