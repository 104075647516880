import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;

    @media only screen and (min-width: 1200px) {
        
    }
`;

const GreetingsTo = ({ setInputState, inputValue }) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='koe'
                engText="you (singular)"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'koe' ? true : false}
            />
            <ButtonChoice
                maoriText='kōrua'
                engText="you both (two people)"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'kōrua' ? true : false}
            />
            <ButtonChoice
                maoriText='koutou'
                engText="you (3 people)"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'koutou' ? true : false}
            />
            <ButtonChoice
                maoriText='koutou katoa'
                engText="you all"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'koutou katoa' ? true : false}
            />
            <ButtonChoice
                maoriText='tātou'
                engText="us"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'tātou' ? true : false}
            />
            <ButtonChoice
                maoriText='tātou katoa'
                engText="us all"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'tātou katoa' ? true : false}
            />
        </ButtonContainer>
    )
}

export default GreetingsTo;