import React from 'react';
import styled from 'styled-components'

const TextMaori = styled.p`
    color: ${props => props.colorTextMaori};
    font-family: MediumFont;
    font-size: 20px;
`;

const TextEng = styled.p`
    color: ${props => props.colorTextEng};
    margin-top: -18px;
    font-size: 15px;
`;


const TextToPrint = ({ textMaori, textEng, toPrintTheme }) => {

    return (
        <>
            <TextMaori colorTextMaori={toPrintTheme ? "#2F373B" : "#FFFFFF"}>{textMaori}</TextMaori>
            <TextEng colorTextEng={toPrintTheme ? "#2F373B" : "#FFFFFF"}>{textEng}</TextEng>
        </>
    )
}

export default TextToPrint;