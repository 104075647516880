import React from 'react';
import WordContainer from './WordContainer';

const T = ({ active }) => {

    return (
        active === "t" &&
        <div>
            <WordContainer
                text="taha"
                file="taha.mp3"
                translation="side"
            />
            <WordContainer
                text="taka"
                file="taka.mp3"
                translation="fall"
            />
            <WordContainer
                text="taki whakaaro"
                file="taki_whakaro.mp3"
                translation="offering ideas"

            />
            <WordContainer
                text="taku"
                file="taku.mp3"
                translation="my (singular)"
            />
            <WordContainer
                text="taku marae"
                file="taku_marae.mp3"
                translation="my cultural village"
            />
            <WordContainer
                text="tamaiti"
                file="tamaiti.mp3"
                translation="child"

            />
            <WordContainer
                text="tamariki"
                file="tamariki.mp3"
                translation="children"
            />
            <WordContainer
                text="tātou"
                file="tatou.mp3"
                translation="us (everyone)"
            />
            <WordContainer
                text="tātou katoa"
                file="tatou_katoa.mp3"
                translation="us (everyone) all"

            />
            <WordContainer
                text="te"
                file="te.mp3"
                translation="the"

            />
            <WordContainer
                text="te aroha"
                file="te_aroha.mp3"
                translation="the love"
            />
            <WordContainer
                text="te kākano"
                file="te_kakano.mp3"
                translation="the seed"
            />
            <WordContainer
                text="te pae tawhiti"
                file="te_pae_tawhiti.mp3"
                translation="the far off destination"

            />
            <WordContainer
                text="tēnā"
                file="tena.mp3"
                translation="that (near you)"

            />
            <WordContainer
                text="tēnei"
                file="tenei.mp3"
                translation="this (near me)"
            />
            <WordContainer
                text="tika"
                file="tika.mp3"
                translation="correct, right"
            />
            <WordContainer
                text="timatanga"
                file="timatanga.mp3"
                translation="beginning"

            />
            <WordContainer
                text="tipu"
                file="tipu.mp3"
                translation="grow"

            />
            <WordContainer
                text="tīpuna"
                file="tipuna.mp3"
                translation="ancestors"

            />
            <WordContainer
                text="tira"
                file="tira.mp3"
                translation="group"

            />
            <WordContainer
                text="toru"
                file="toru.mp3"
                translation="three"

            />
            <WordContainer
                text="tū"
                file="tu.mp3"
                translation="stand, stop"

            />
            <WordContainer
                text="Tū tira mai ngā iwi"
                file="tu_tira_mai_nga_iwi.mp3"
                translation="stand together all peoples"
                border
            />
        </div>
    )
}

export default T;