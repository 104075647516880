import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Title from '../Title'
import Button from '../Button';
import ButtonOption from '../ButtonOption';
import ErrorMessage from '../ErrorMessage';

const ContainerOut = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 90vh;
`;

const Container = styled.div`
    text-align: center;
    width: 280px;

    @media only screen and (min-width: 768px) {
        width: 400px;
    }
`;

const AreYouMaori = ({ setIsMaori, isMaori }) => {
    const [showError, setShowError] = useState(false)
    return (
        <ContainerOut>
            <Container>
                <img src="/svg/pepeha-are-you-maori.svg" alt="Are you Māori?" />
                <Title title="Are you Māori?" />

                <ButtonOption
                    text="Yes. I am Māori"
                    setBtState={setIsMaori}
                    btState={true}
                    active={isMaori === true}
                />
                <ButtonOption
                    text="No. I am a New Zealander of non-Māori origin or I am from overseas."
                    setBtState={setIsMaori}
                    btState={false}
                    active={isMaori === false}
                />
                {showError && <ErrorMessage text="Please select an option above" />}

                {
                    isMaori === "error" ?
                        <div onClick={() => setShowError(true)}>
                            <Button text="Next" />
                        </div>
                        :
                        <Link to={isMaori ? "/pepeha/maori/0.5" : "/pepeha/non-maori/0.5"}>
                            <Button text="Next" />
                        </Link>
                }
            </Container>
        </ContainerOut>
    )
}

export default AreYouMaori;