import React from 'react';
import WordContainer from './WordContainer';

const A = ({ active }) => {

    return (
        active === "a" &&
        <div>
            <WordContainer
                text="ā"
                file="a.mp3"
                translation="and, and then"
            />
            <WordContainer
                text="ahau"
                file="ahau.mp3"
                translation="me, I"
            />
            {/* <WordContainer
                text="ana"
                file="ana.mp3"
                translation="my (plural)"
            /> */}

            <WordContainer
                text="aku"
                file="aku.mp3"
                translation="my (plural)"
            />

            <WordContainer
                text="anō"
                file="ano.mp3"
                translation="again, indeed"
            />
            <WordContainer
                text="aroha"
                file="aroha.mp3"
                translation="love, care"
            />
            <WordContainer
                text="awa"
                file="awa.mp3"
                translation="river"
                border
            />
        </div>
    )
}

export default A;