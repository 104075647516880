import React from 'react';
import WordContainer from './WordContainer';

const O = ({ active }) => {

    return (
        active === "o" &&
        <div>
            <WordContainer
                text="o"
                file="o.mp3"
                translation="of"
            />
            <WordContainer
                text="orokati"
                file="orokati.mp3"
                translation="consonant"
            />
            <WordContainer
                text="oropuare"
                file="oropuare.mp3"
                translation="vowel"
                border
            />
        </div>
    )
}

export default O;