import React from 'react';
import WordContainer from './WordContainer';

const W = ({ active }) => {

    return (
        active === "w" &&
        <div>
            <WordContainer
                text="waiata"
                file="waiata.mp3"
                translation="song, sing"
            />
            <WordContainer
                text="waka"
                file="waka.mp3"
                translation="canoe, vehicle"
            />
            <WordContainer
                text="whā"
                file="wha.mp3"
                translation="four"

            />
            <WordContainer
                text="whai rawa"
                file="whai_rawa.mp3"
                translation="business person, entrepreneur "

            />
            <WordContainer
                text="whaiāipo"
                file="whaiaipo.mp3"
                translation="sweetheart"

            />
            <WordContainer
                text="whakahua"
                file="whakahua.mp3"
                translation="pronounce"
            />
            <WordContainer
                text="whakahua tika"
                file="whakahua_tika.mp3"
                translation="pronounce correctly"

            />
            <WordContainer
                text="whakapapa"
                file="whakapapa.mp3"
                translation="lineage"

            />
            <WordContainer
                text="whānau"
                file="whanau.mp3"
                translation="family"
                border
            />

        </div>
    )
}

export default W;