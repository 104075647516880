import React from 'react';
import KarakiaTextGroup from './KarakiaTextGroup'

const LyricOne = () => {

    return (
        <div>
            <KarakiaTextGroup
                textMaori="Tuia ki te rangi"
                textEng="Dedicated to the sky"
            />
            <KarakiaTextGroup
                textMaori="Tuia ki te whenua"
                textEng="and to the earth below "
            />
            <KarakiaTextGroup
                textMaori="Tuia ki te"
                textEng="human kind are bound"
            />
            <KarakiaTextGroup
                textMaori="Ira tangata"
                textEng="and inseperable"
            />
            <KarakiaTextGroup
                textMaori="Ka rongo te pō	"
                textEng="From the infinite potential"
            />
            <KarakiaTextGroup
                textMaori="Ka rongo te ao"
                textEng="to the realization in light"
            />
            <KarakiaTextGroup
                textMaori="Tūturu whakamaua"
                textEng="Let us come together"
            />
            <KarakiaTextGroup
                textMaori="Kia tīna"
                textEng="with purpose"
            />
            <KarakiaTextGroup
                textMaori="Haumi e Hui e"
                textEng="united and ready"
            />
            <KarakiaTextGroup
                textMaori="Tāiki e"
                textEng="to progress"
            />
        </div>
    )
}

export default LyricOne;