import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;
`;

const Concerning = ({
    setInputState,
    inputValue
}) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='tēnei marae'
                engText="this marae"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "tēnei marae" ? true : false}
            />
            <ButtonChoice
                maoriText="tēnei whare"
                engText="this house/building"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "tēnei whare" ? true : false}
            />
            <ButtonChoice
                maoriText="tēnei wāhi"
                engText="this place"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "tēnei wāhi" ? true : false}
            />
            <ButtonChoice
                maoriText="tēnei hui"
                engText="this meeting"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "tēnei hui" ? true : false}
            />
            <ButtonChoice
                maoriText="tēnei whakaminenga"
                engText="this assembly"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "tēnei whakaminenga" ? true : false}
            />
            <ButtonChoice
                maoriText="Aotearoa nei"
                engText="New Zealand"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "Aotearoa nei" ? true : false}
            />
        </ButtonContainer>
    )
}

export default Concerning;