import React from 'react';
import KarakiaTextGroup from './KarakiaTextGroup'

const LyricSeven = () => {

    return (
        <div>
            <KarakiaTextGroup
                textMaori="Kia tau kia tātou katoa"
                textEng="May peace, love, and safety"
            />
            <KarakiaTextGroup
                textMaori="Te āio, te aroha me te marutau"
                textEng="Be upon us all"
            />
            <KarakiaTextGroup
                textMaori="Tīhei mauri ora"
                textEng="a promise of a glorious day"
            />

        </div>
    )
}

export default LyricSeven;