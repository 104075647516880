import React from 'react';
import WaiataTextGroup from './WaiataTextGroup'

const LyricSix = () => {

    return (
        <div>
            <WaiataTextGroup
                textMaori="Ringaringa pakia, waewae takahia"
                textEng="Hands slapping, feet stomping"
            />
            <WaiataTextGroup
                textMaori="Kia kino nei hoki!"
                textEng="Bad to the bone!"
            />
            <WaiataTextGroup
                textMaori="E kino nei hoki!"
                textEng="Bad to the bone indeed!"
            />
            <WaiataTextGroup
                textMaori="Ka mate, ka mate!"
                textEng="Tis death, tis death!"
            />
            <WaiataTextGroup
                textMaori="Ka ora, ka ora"
                textEng="Tis life, tis life!"
            />
            <WaiataTextGroup
                textMaori="Ka mate, ka mate!"
                textEng="Tis death, tis death!"
            />
            <WaiataTextGroup
                textMaori="Ka ora, ka ora"
                textEng="Tis life, tis life!"
            />
            <WaiataTextGroup
                textMaori="Tēnei te tangata pūruhuruhuru"
                textEng="What is this hairy thing"
            />
            <WaiataTextGroup
                textMaori="Nāna i tiki mai whakawhiti te rā?"
                textEng="That has caused the sun to shine?"
            />

            <WaiataTextGroup
                textMaori="Hupāne, kaupāne!"
                textEng="Up this step, up that step"
            />
            <WaiataTextGroup
                textMaori="Hupāne, kaupāne!"
                textEng="Up this step, up that step"
            />
            <WaiataTextGroup
                textMaori="Whiti te rā!"
                textEng="To sun shines brightly!"
            />
            <WaiataTextGroup
                textMaori="Ka mate, ka mate!"
                textEng="Tis death, tis death!"
            />
            <WaiataTextGroup
                textMaori="Ka ora, ka ora"
                textEng="Tis life, tis life!"
            />
            <WaiataTextGroup
                textMaori="Ka mate, ka mate!"
                textEng="Tis death, tis death!"
            />
            <WaiataTextGroup
                textMaori="Ka ora, ka ora"
                textEng="Tis life, tis life!"
            />
            <WaiataTextGroup
                textMaori="Tēnei te tangata pūruhuruhuru"
                textEng="What is this hairy thing"
            />
            <WaiataTextGroup
                textMaori="Nāna i tiki mai whakawhiti te rā?"
                textEng="That has caused the sun to shine?"
            />
            <WaiataTextGroup
                textMaori="Hupāne, kaupāne!"
                textEng="Up this step, up that step"
            />
            <WaiataTextGroup
                textMaori="Hupāne, kaupāne!"
                textEng="Up this step, up that step"
            />
            <WaiataTextGroup
                textMaori="Whiti te rā!"
                textEng="To sun shines brightly!"
            />
            <WaiataTextGroup
                textMaori="Hi!"
                textEng="It is done! "
            />
        </div>

    )
}

export default LyricSix;