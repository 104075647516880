import React from 'react';
import styled from 'styled-components'

const TitleStyled = styled.h1`
    font-size: 20px;
    margin: 30px 0;

    @media only screen and (min-width: 768px) {
        font-size: 30px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 30px;
    }
`;


const Title = ({ title }) => {
    return <TitleStyled>{title}</TitleStyled>
}

export default Title;