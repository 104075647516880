import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components'

import InputQuestion from '../InputQuestion';
import SectionToPrint from '../SectionToPrint'
import IsNotMaoriPepehaToPrintUi from './IsNotMaoriPepehaToPrintUi';
import IsNotMaoriPepehaToPrint from './IsNotMaoriPepehaToPrint';
import ExportToPDFButton from '../ExportToPDFButton';
import PopUpLoading from '../PopUpLoading';
// import PopUpPepeha from '../PopUpPepeha'
import Button from '../Button';
import BasicAdvanced from '../BasicAdvanced';
import {
    whereBorn,
    whereLiving,
    ancestors,
    whanau,
    name,
    mountain,
    hill,
    sea,
    river,
    lake,
    tribe
} from './IsNotMaoriPepehaSentences';

const ContainerToPrint = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ContainerOut = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 90vh;
`;

const ContainerIn = styled.div`
    width: 100%

    @media only screen and (min-width: 768px) {
        width: 400px;
    }
`;

const ToPrintContainer = styled.div`
    margin: 0 10px 50px 0;
    width: 280px;

    @media only screen and (min-width: 768px) {
        width: 600px;
    }
`;

const IsNotMaori = (
    {
        inputValueAncestors,
        setInputStateAncestors,
        inputValueHill,
        setInputStateHill,
        inputValueMountain,
        setInputStateMountain,
        inputValueRiver,
        setInputStateRiver,
        inputValueLake,
        setInputStateLake,
        inputValueSea,
        setInputValueSea,
        inputValueTribe,
        setInputValueTribe,
        inputValueName,
        setInputValueName,
        inputValueWhereBorn,
        setInputValueWhereBorn,
        inputValueWhereLiving,
        setInputValueWhereLiving,
        inputValueWhanau,
        setInputValueWhanau,
    }
) => {
    let { flow } = useParams()
    let history = useHistory()
    const [toPrintTheme, setToPrintTheme] = useState(true)
    const [isBasic, setIsBasic] = useState("error")
    const clearHistory = function() {
        setInputStateAncestors("")
        setInputStateHill("")
        setInputStateMountain("")
        setInputStateRiver("")
        setInputStateLake("")
        setInputValueSea("")
        setInputValueTribe("")
        setInputValueName("")
        setInputValueWhereBorn("")
        setInputValueWhereLiving("")
        setInputValueWhanau("")
    }
    return (
        <div>
            <ContainerToPrint>
            {
                    flow === "13" &&
                    <ToPrintContainer>
                        <SectionToPrint
                            imgSrc="/svg/pepeha-red.svg"
                            title="Pepeha"
                            toPrintTheme={toPrintTheme}
                            toPrintUI={
                                <IsNotMaoriPepehaToPrintUi
                                    inputValueAncestors={inputValueAncestors}
                                    inputValueHill={inputValueHill}
                                    inputValueMountain={inputValueMountain}
                                    inputValueRiver={inputValueRiver}
                                    inputValueLake={inputValueLake}
                                    inputValueSea={inputValueSea}
                                    inputValueTribe={inputValueTribe}
                                    inputValueName={inputValueName}
                                    inputValueWhereBorn={inputValueWhereBorn}
                                    inputValueWhereLiving={inputValueWhereLiving}
                                    inputValueWhanau={inputValueWhanau}
                                    setToPrintTheme={setToPrintTheme}
                                    toPrintTheme={toPrintTheme}
                                    isBasic={isBasic}
                                />
                            }
                        />
                        <ExportToPDFButton
                            toPrint={
                                <IsNotMaoriPepehaToPrint
                                    inputValueAncestors={inputValueAncestors}
                                    inputValueHill={inputValueHill}
                                    inputValueMountain={inputValueMountain}
                                    inputValueRiver={inputValueRiver}
                                    inputValueLake={inputValueLake}
                                    inputValueSea={inputValueSea}
                                    inputValueTribe={inputValueTribe}
                                    inputValueName={inputValueName}
                                    inputValueWhereBorn={inputValueWhereBorn}
                                    inputValueWhereLiving={inputValueWhereLiving}
                                    inputValueWhanau={inputValueWhanau}
                                    toPrintTheme={toPrintTheme}
                                    isBasic={isBasic}
                                />
                            }
                            fileName="Pepeha.pdf"
                        />
                        <Link onClick={clearHistory} to="/pepeha/non-maori/0.5">
                            <Button
                                text="Start over"
                                ArgBackgroundColor="white"
                                ArgBorder="1px solid #D5002B"
                                ArgColor="#D5002B"
                                ArgHoverBackgroundColor="#F0F0F0"
                            />
                        </Link>
                    </ToPrintContainer>
                }
            </ContainerToPrint>
            {
                flow < 13 &&
                <ContainerOut>
                    <ContainerIn>

                        {
                            flow === "0.5" &&
                            <BasicAdvanced
                                setState={setIsBasic}
                                state={isBasic}
                                image="/svg/mountain.svg"
                                basic="Basic pepeha"
                                advanced="Advanced pepeha"
                                path="/pepeha/non-maori/1"
                                title="Create my pepeha"
                                onClick={clearHistory}
                            />
                        }
                    {
                        isBasic === true &&
                        <div>
                            {
                            flow === "1" &&
                            <InputQuestion
                                path="/pepeha/non-maori/2"
                                inputValue={inputValueAncestors}
                                setInputState={setInputStateAncestors}
                                imgSrc="/svg/ancestors.svg"
                                title={ancestors(inputValueAncestors)[0]}
                                text={ancestors(inputValueAncestors)[1]}
                                placeholder="Ancestors place"
                            />
                            }
                            {
                            flow === "2" &&
                            <InputQuestion
                                path="/pepeha/non-maori/3"
                                inputValue={inputValueWhanau}
                                setInputState={setInputValueWhanau}
                                imgSrc="/svg/whanau.svg"
                                title={whanau(inputValueWhanau)[0]}
                                text={whanau(inputValueWhanau)[1]}
                                placeholder="Family name"
                            />
                            }
                            {
                                flow === "3" &&
                                <InputQuestion
                                    path="/pepeha/non-maori/4"
                                    inputValue={inputValueWhereBorn}
                                    setInputState={setInputValueWhereBorn}
                                    imgSrc="/svg/was-born.svg"
                                    title={whereBorn(inputValueWhereBorn)[0]}
                                    text={whereBorn(inputValueWhereBorn)[1]}
                                    placeholder="Place name"
                                />
                            }
                            {
                                flow === "4" &&
                                <InputQuestion
                                    path="/pepeha/non-maori/5"
                                    inputValue={inputValueWhereLiving}
                                    setInputState={setInputValueWhereLiving}
                                    imgSrc="/svg/i-live-in.svg"
                                    title={whereLiving(inputValueWhereLiving)[0]}
                                    text={whereLiving(inputValueWhereLiving)[1]}
                                    placeholder="Place name"
                                />
                            }
                        
                            {
                                flow === "5" &&
                                <InputQuestion
                                    path="/pepeha/non-maori/12"
                                    inputValue={inputValueName}
                                    setInputState={setInputValueName}
                                    imgSrc="/svg/name.svg"
                                    title={name(inputValueName)[0]}
                                    text={name(inputValueName)[1]}
                                    placeholder="Your name"
                                />
                            }
                        </div>
                    }
                        {/* {
                            flow === "5" &&
                            <PopUpPepeha />
                        } */}
                        {
                            isBasic === false &&
                            <div>
                                {
                                    flow === "1" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/2"
                                        inputValue={inputValueAncestors}
                                        setInputState={setInputStateAncestors}
                                        imgSrc="/svg/ancestors.svg"
                                        title={ancestors(inputValueAncestors)[0]}
                                        text={ancestors(inputValueAncestors)[1]}
                                        placeholder="Ancestors place"
                                    />
                                }
                                {
                                    flow === "2" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/3"
                                        inputValue={inputValueWhanau}
                                        setInputState={setInputValueWhanau}
                                        imgSrc="/svg/whanau.svg"
                                        title={whanau(inputValueWhanau)[0]}
                                        text={whanau(inputValueWhanau)[1]}
                                        placeholder="Family name"
                                    />
                                }
                                {
                                    flow === "3" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/4"
                                        inputValue={inputValueWhereBorn}
                                        setInputState={setInputValueWhereBorn}
                                        imgSrc="/svg/was-born.svg"
                                        title={whereBorn(inputValueWhereBorn)[0]}
                                        text={whereBorn(inputValueWhereBorn)[1]}
                                        placeholder="Place name"
                                    />
                                }
                                {
                                    flow === "4" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/5"
                                        inputValue={inputValueMountain}
                                        setInputState={setInputStateMountain}
                                        imgSrc="/svg/mountain.svg"
                                        title={mountain(inputValueMountain)[0]}
                                        text={mountain(inputValueMountain)[1]}
                                        placeholder="Mountain"
                                    />
                                }
                                {
                                    flow === "5" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/6"
                                        inputValue={inputValueHill}
                                        setInputState={setInputStateHill}
                                        imgSrc="/svg/hill.svg"
                                        title={hill(inputValueHill)[0]}
                                        text={hill(inputValueHill)[1]}
                                        placeholder="Hill"
                                    />
                                }
                                {
                                    flow === "6" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/7"
                                        inputValue={inputValueLake}
                                        setInputState={setInputStateLake}
                                        imgSrc="/svg/lake.svg"
                                        title={lake(inputValueLake)[0]}
                                        text={lake(inputValueLake)[1]}
                                        placeholder="Lake name"
                                    />
                                }
                                {
                                    flow === "7" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/8"
                                        inputValue={inputValueRiver}
                                        setInputState={setInputStateRiver}
                                        imgSrc="/svg/river.svg"
                                        title={river(inputValueRiver)[0]}
                                        text={river(inputValueRiver)[1]}
                                        placeholder="River name"
                                    />
                                }
                                {
                                    flow === "8" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/9"
                                        inputValue={inputValueSea}
                                        setInputState={setInputValueSea}
                                        imgSrc="/svg/sea.svg"
                                        title={sea(inputValueSea)[0]}
                                        text={sea(inputValueSea)[1]}
                                        placeholder="Sea name"
                                    />
                                }
                                {
                                    flow === "9" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/10"
                                        inputValue={inputValueWhereLiving}
                                        setInputState={setInputValueWhereLiving}
                                        imgSrc="/svg/i-live-in.svg"
                                        title={whereLiving(inputValueWhereLiving)[0]}
                                        text={whereLiving(inputValueWhereLiving)[1]}
                                        placeholder="Place name"
                                    />
                                }
                                {
                                    flow === "10" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/11"
                                        inputValue={inputValueTribe}
                                        setInputState={setInputValueTribe}
                                        imgSrc="/svg/tribe.svg"
                                        title={tribe(inputValueTribe)[0]}
                                        text={tribe(inputValueTribe)[1]}
                                        placeholder="Tribe name"
                                    />
                                }      
                                {
                                    flow === "11" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/12"
                                        inputValue={inputValueName}
                                        setInputState={setInputValueName}
                                        imgSrc="/svg/name.svg"
                                        title={name(inputValueName)[0]}
                                        text={name(inputValueName)[1]}
                                        placeholder="Your name"
                                    />
                                }

                            </div>
                        }

                        {
                            flow === "12" &&
                            <div onClick={setTimeout(function () { history.push("/pepeha/non-maori/13") }, 3000)}>
                                <PopUpLoading word="pepeha" />
                            </div>
                        }

                    </ContainerIn>
                </ContainerOut>
            }
        </div>
    )
}

export default IsNotMaori;