import React from 'react';
import WordContainer from './WordContainer';

const M = ({ active }) => {

    return (
        active === "m" &&
        <div>
            {/* <WordContainer
                text="mā"
                file="ma.mp3"
                translation=""
            /> */}
            <WordContainer
                text="mai"
                file="mai.mp3"
                translation="a particle indicating towards speakers direction"
            />

            <WordContainer
                text="māmā"
                file="mama.mp3"
                translation="mother"
            />
            <WordContainer
                text="manaaki"
                file="manaki.mp3"
                translation="care"
            />
            <WordContainer
                text="manawanui"
                file="manawanui.mp3"
                translation="dedicated"
            />
            <WordContainer
                text="manuhiri"
                file="manuhiri.mp3"
                translation="guest"
            />
            <WordContainer
                text="Māori"
                file="maori.mp3"
                translation="Indigenous people of Aotearoa"
            />
            <WordContainer
                text="marae"
                file="marae.mp3"
                translation="cultural centre"
            />
            <WordContainer
                text="maru"
                file="maru.mp3"
                translation="shelter, shadow"
            />
            <WordContainer
                text="mate"
                file="mate.mp3"
                translation="unwell, perish"
            />
            <WordContainer
                text="maunga"
                file="maunga.mp3"
                translation="mountain"
            />
            <WordContainer
                text="mea"
                file="mea.mp3"
                translation="thing"
            />

            <WordContainer
                text="mihi"
                file="mihi.mp3"
                translation="greeting"
            />
            <WordContainer
                text="mō"
                file="mo.mp3"
                translation="for, about"
            />
            <WordContainer
                text="mō tēnei rauemi"
                file="mo_tenei_rauemi.mp3"
                translation="about this resource"
            />
            <WordContainer
                text="moana"
                file="moana.mp3"
                translation="sea"
            />
        </div>
    )
}

export default M;