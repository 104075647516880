import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;
`;

const Therefore = ({
    setInputState,
    inputValue
}) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='e hoa'
                engText="my friend"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'e hoa' ? true : false}
            />
            <ButtonChoice
                maoriText='e hoa mā'
                engText="my friends"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'e hoa mā' ? true : false}
            />
            <ButtonChoice
                maoriText='e te whānau'
                engText="my family"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'e te whānau' ? true : false}
            />
            <ButtonChoice
                maoriText='e te manuhiri'
                engText="my visitor's"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'e te manuhiri' ? true : false}
            />
            <ButtonChoice
                maoriText='e aku rangatira'
                engText="my chiefs, superiors"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'e aku rangatira' ? true : false}
            />
            <ButtonChoice
                maoriText='e ngā mana'
                engText="important persons"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'e ngā mana' ? true : false}
            />
        </ButtonContainer>
    )
}

export default Therefore;