import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ScrollToTop from './ScrollToTop'
import ReactGA from 'react-ga';
import Home from "./pages/Home"
import Mihimihi from './pages/Mihimihi'
import Pepeha from './pages/Pepeha'
import Whakapapa from './pages/Whakapapa'

import Glossary from './pages/Glossary'
import Framework from './pages/Framewrok'
import About from './pages/About'
import TopBar from './components/TopBar'
import Karakia from "./pages/Karakia";
import Akona from "./pages/Akona";
import Waiata from "./pages/Waiata";

const TRACKING_ID = "G-TW4CZS2B3E"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {

  return (
    <Router>
      <ScrollToTop />
      <div>
        <TopBar />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/mihimihi/:mihimihiView/:flow">
            <Mihimihi />
          </Route>
          <Route path="/pepeha/:pepehaView/:flow">
            <Pepeha />
          </Route>
          <Route path="/whakapapa/:whakapapaView/:flow">
            <Whakapapa />
          </Route>
          <Route path="/glossary">
            <Glossary />
          </Route>
          <Route path="/framework">
            <Framework />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/karakia/:view">
            <Karakia />
          </Route>
          <Route path="/akona/:view">
            <Akona />
          </Route>
          <Route path="/waiata/:view">
            <Waiata />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;