import React from 'react'
import styled from 'styled-components'

const TextTip = styled.p`
    font-family: MediumFont;
`;

const Span = styled.span`
    font-family: MediumFont;
    color: #DA1710;
    cursor: pointer;
    text-decoration: underline;
`;

const SectionIntroHandlePopUp = ({ setShowPopUp, text }) => {
    return (
        <div>
            <p>{text} {' '}
                {setShowPopUp && <Span onClick={() => setShowPopUp(true)}>Learn more.</Span>}
            </p>
            {/* <TextTip>Tip: Skip any sections that don’t apply</TextTip> */}
        </div>
    )
}


export default SectionIntroHandlePopUp