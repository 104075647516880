import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;
`;

const IamFeeling = ({
    setInputState,
    inputValue
}) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='hari'
                engText="joy"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'hari' ? true : false}
            />
            <ButtonChoice
                maoriText='koa'
                engText="happiness"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'koa' ? true : false}
            />
            <ButtonChoice
                maoriText='matakuikui'
                engText="overjoyed"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'matakuikui' ? true : false}
            />
            <ButtonChoice
                maoriText='pōuri'
                engText="sadness"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'pōuri' ? true : false}
            />
            <ButtonChoice
                maoriText='mihi'
                engText="gratitude"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'mihi' ? true : false}
            />
            <ButtonChoice
                maoriText='whakamiha'
                engText="acknowledge"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'whakamiha' ? true : false}
            />
        </ButtonContainer>
    )
}

export default IamFeeling;