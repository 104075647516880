import React from 'react';
import WaiataTextGroup from './WaiataTextGroup'

const LyricFive = () => {

    return (
        <div>
            <WaiataTextGroup
                textMaori="Ka mate ahau"
                textEng="I would die"
            />
            <WaiataTextGroup
                textMaori="I te aroha e"
                textEng="of love for you"
            />
            <WaiataTextGroup
                textMaori="Pōkarekare ana"
                textEng="Agitated"
            />
            <WaiataTextGroup
                textMaori="Ngā wai o Waiapu"
                textEng="are the waters of Waiapu"
            />
            <WaiataTextGroup
                textMaori="Whiti atu koe hine"
                textEng="when you cross over my dearest"
            />
            <WaiataTextGroup
                textMaori="Mārino ana e"
                textEng="they will become calm"
            />
            <WaiataTextGroup
                textMaori="E hine e"
                textEng="oh dear one"
            />
            <WaiataTextGroup
                textMaori="Hoki mai rā"
                textEng="please come back"
            />
            <WaiataTextGroup
                textMaori="Ka mate au"
                textEng="I would die"
            />
            <WaiataTextGroup
                textMaori="i te aroha e"
                textEng="of love for you"
            />
            <WaiataTextGroup
                textMaori="e hine e"
                textEng="oh dear one"
            />
            <WaiataTextGroup
                textMaori="Hoki mai rā"
                textEng="please come back"
            />
            <WaiataTextGroup
                textMaori="Ka mate ahau"
                textEng="I would die"
            />
            <WaiataTextGroup
                textMaori="i te aroha e"
                textEng="of love for you"
            />

        </div>

    )
}

export default LyricFive;