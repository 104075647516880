import React from 'react';
import styled from 'styled-components'
import ButtonTapPlay from '../ButtonTapPlay';
import SoundIcon from '../SoundIcon';

const OutContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 30px;
`;

const TextContainer = styled.div`
    @media only screen and (max-width: 768px) {
        margin: 0 20px;;
    }

    @media only screen and (min-width: 768px) {
        width: 380px;
    }
`;

const SubTitle = styled.p`
    font-size: 20px;
    margin: 50px 0 30px 0;
`;

const BtContainer = styled.div`
    margin-bottom: 30px;
`;

const S3_URL = "https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Diphthongs/"

const Diphthongs = () => {

    return (
        <OutContainer>
            <div>
                <TextContainer>
                    <p>
                        Click in the ten Māori diphthongs to hear the correct pronunciation.
                    </p>
                </TextContainer>


                <SubTitle>Diphthongs <span><SoundIcon /></span></SubTitle>
                <BtContainer>
                    <ButtonTapPlay text="aa" url={`${S3_URL}aa.mp3`} />
                    <ButtonTapPlay text="ee" url={`${S3_URL}ee.mp3`} />
                    <ButtonTapPlay text="ii" url={`${S3_URL}ii.mp3`} />
                    <ButtonTapPlay text="oo" url={`${S3_URL}oo.mp3`} />
                    <ButtonTapPlay text="uu" url={`${S3_URL}uu.mp3`} />
                </BtContainer>
                <BtContainer>
                    <ButtonTapPlay text="ae" url={`${S3_URL}ae.mp3`} />
                    <ButtonTapPlay text="ea" url={`${S3_URL}ea.mp3`} />
                    <ButtonTapPlay text="ia" url={`${S3_URL}ia.mp3`} />
                    <ButtonTapPlay text="oa" url={`${S3_URL}oa.mp3`} />
                    <ButtonTapPlay text="ua" url={`${S3_URL}ua.mp3`} />
                </BtContainer>
                <BtContainer>
                    <ButtonTapPlay text="ai" url={`${S3_URL}ai.mp3`} />
                    <ButtonTapPlay text="ei" url={`${S3_URL}ei.mp3`} />
                    <ButtonTapPlay text="ie" url={`${S3_URL}ie.mp3`} />
                    <ButtonTapPlay text="oe" url={`${S3_URL}oe.mp3`} />
                    <ButtonTapPlay text="ue" url={`${S3_URL}ue.mp3`} />
                </BtContainer>
                <BtContainer>
                    <ButtonTapPlay text="ao" url={`${S3_URL}ao.mp3`} />
                    <ButtonTapPlay text="eo" url={`${S3_URL}eo.mp3`} />
                    <ButtonTapPlay text="io" url={`${S3_URL}io.mp3`} />
                    <ButtonTapPlay text="oi" url={`${S3_URL}oi.mp3`} />
                    <ButtonTapPlay text="ui" url={`${S3_URL}ui.mp3`} />
                </BtContainer>
                <BtContainer>
                    <ButtonTapPlay text="au" url={`${S3_URL}au.mp3`} />
                    <ButtonTapPlay text="eu" url={`${S3_URL}eu.mp3`} />
                    <ButtonTapPlay text="iu" url={`${S3_URL}iu.mp3`} />
                    <ButtonTapPlay text="ou" url={`${S3_URL}ou.mp3`} />
                    <ButtonTapPlay text="uo" url={`${S3_URL}uo.mp3`} />
                </BtContainer>

            </div>
        </OutContainer>
    )
}

export default Diphthongs;