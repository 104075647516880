import React from 'react';
import styled from 'styled-components'
import ButtonTapPlay from '../ButtonTapPlay';
import SoundIcon from '../SoundIcon';

const OutContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 30px;
`;

const TextContainer = styled.div`
    @media only screen and (max-width: 768px) {
        margin: 0 20px;;
    }

    @media only screen and (min-width: 768px) {
        width: 380px;
    }
`;

const SubTitle = styled.p`
    font-size: 20px;
    margin: 50px 0 30px 0;
`;

const BtContainer = styled.div`
    margin-bottom: 30px;
`;

const S3_URL = "https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/whakahua/Consonants/"

const Consonants = () => {

    return (
        <OutContainer>
            <div>
                <TextContainer>
                    <p>
                        Click in the ten Māori consonants to hear the correct pronunciation.
                    </p>
                </TextContainer>


                <SubTitle>Consonants <span><SoundIcon /></span></SubTitle>
                <BtContainer>
                    <ButtonTapPlay text="h" url={`${S3_URL}h.mp3`} />
                    <ButtonTapPlay text="k" url={`${S3_URL}k.mp3`} />
                    <ButtonTapPlay text="m" url={`${S3_URL}m.mp3`} />
                    <ButtonTapPlay text="n" url={`${S3_URL}n.mp3`} />
                    <ButtonTapPlay text="p" url={`${S3_URL}p.mp3`} />
                </BtContainer>

                <BtContainer>
                    <ButtonTapPlay text="r" url={`${S3_URL}r.mp3`} />
                    <ButtonTapPlay text="t" url={`${S3_URL}t.mp3`} />
                    <ButtonTapPlay text="w" url={`${S3_URL}w.mp3`} />
                    <ButtonTapPlay text="ng" url={`${S3_URL}ng.mp3`} />
                    <ButtonTapPlay text="wh" url={`${S3_URL}wh.mp3`} />
                </BtContainer>

                <TextContainer>
                    <p>
                        Two of the consonants are digraphs, where two letters combine two form a sound"
                    </p>
                </TextContainer>

                <TextContainer>
                    <p>
                        <strong>ng</strong> as in singer
                    </p>
                    <p>
                        <strong>wh</strong> as in fish
                    </p>
                </TextContainer>

                <TextContainer>
                    <p>
                        <strong>r</strong> as in cheddar
                    </p>
                </TextContainer>
            </div>
        </OutContainer>
    )
}

export default Consonants;