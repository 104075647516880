import React from 'react';
import WaiataTextGroup from './WaiataTextGroup'

const LyricSeven = () => {

    return (
        <div>

            <WaiataTextGroup
                textMaori="Ehara i te mea"
                textEng="It is not that"
            />
            <WaiataTextGroup
                textMaori="Nō nāianei te aroha"
                textEng="Love is a new thing"
            />
            <WaiataTextGroup
                textMaori="Nō ngā tūpuna"
                textEng="From our ancestors"
            />
            <WaiataTextGroup
                textMaori="I tuku iho, i tuku iho"
                textEng="A gift handed down"
            />

        </div>

    )
}

export default LyricSeven;