import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Title from './Title'
import Button from './Button';
import ErrorMessage from './ErrorMessage';

const Container = styled.div`
    margin: 0 20px;
    text-align: center;
    width: 280px;

    @media only screen and (min-width: 768px) {
        width: 400px;
    }
`;

const Text = styled.p`
    font-size: 12px;
    margin: -25px 0 30px 0;

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 20px;
    }
`;


const ButtonQuestion = ({ title, text, path, buttons, state }) => {
    const [showError, setShowError] = useState(false)
    if (buttons.props.inputValue == []){
        state = "error"
    }
    return (

        <Container>
            <Title title={title} />
            <Text>{text}</Text>
            {buttons}
            {showError && <ErrorMessage text="Please select an option above" />}
            {
                    state === "error" ?
                        <div onClick={() => setShowError(true)}>
                            <Button text="Next" />
                        </div>
                        :
                        <Link to={path}>
                            <Button text="Next" />
                        </Link>
            }
        </Container>

    )
}

export default ButtonQuestion;