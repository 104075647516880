import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// import BackgroundImage from '../components/BackgroundImage';



const ContainerAnima = styled.div`
    background-color: #E11710;
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow: scroll;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 80px 0;
    align-items: center;

    @media only screen and (min-width: 768px) {
        padding: 300px 0;
    }

    @media only screen and (min-width: 1000px) {
        padding: 150px 0;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

const Title = styled.h1`
    color: white;
    font-size: 20px
`;

const Text = styled.p`
    color: white;
    font-size: 14px;
    margin: 15px 10px 50px 10px;

    @media only screen and (min-width: 768px) {
        font-size: 20px;
        margin: 45px 10px 50px 10px;
    }
`;

const Button = styled.button`
    background-color: white;
    border: none;
    border-radius: 10px;  
    box-shadow: "0 4px 8px 0 rgba(0,0,0,0.2)";
    color: #2D373C;
    cursor: pointer;
    margin: 0 15px 10px 15px;
    padding: 2px;
    
`;

const Icon = styled.img` 
    margin: 0 10px;      
    width: 30px;

    @media only screen and (min-width: 768px) {
        width: 40px;
    }
`;

const ArroRight = styled.img` 
    margin: 0 10px;      
    width: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const IconTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
`;

const ButtonText = styled.p`       
    font-size: 14px;

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }
`;
const ButtonTextSpan = styled.span`       
    color: #B2B2B2;
`;

// Anima

const Box = styled.div`
    position: fixed;
    background: ${props => props.backgroundColor};
    width: 2000px;
    height: 2000px;
    margin: 110vh 0 0 calc(-2000px + 50vw);
    transition: transform 1s linear;
    transform-origin: top right;
    transform-style: preserve-3D;
    z-index: -109;

    @-webkit-keyframes rotating /* Safari and Chrome */ {
        from {
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @keyframes rotating {
        from {
          -ms-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -ms-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

    -webkit-animation: rotating 2s linear;
    -moz-animation: rotating 2s linear;
    -ms-animation: rotating 2s linear;
    -o-animation: rotating 2s linear;
    animation: rotating 2s linear;
    animation-delay: ${props => props.delay};
`;

const Logo = styled.img`
    margin: 2px 0 0 2px;
    width: 160px;

    @media only screen and (min-width: 768px) {
        width: 250px;
    }

    @keyframes transformanim{
        100%{
          transform:scale(1) translateX(0px); /*1.5s here*/
          opacity:1;
        }
        80%{
            opacity:1;
          }
        0%{
            transform:scale(1.5) translateX(0px);
            opacity:0;
          }
      }

      @-webkit-keyframes fadein{
        0% { opacity:0; }
        66% { opacity:1; }
        100% { opacity:1; }
    }

      animation: transformanim 2.5s ease-out forwards;    
    
`;

const LogoContainer = styled.div`
    height: 120px

    @media only screen and (min-width: 768px) {
        height: 350px

    }
    
`;


const Home = () => {

    useEffect(() => {
        Analytics.record({
            name: "Home page visit"
        })
    }, [])

    // useEffect(() => {
    //     Analytics.record({
    //         name: "Home page visit test"
    //     })
    // }, [])

    return (
        <ContainerAnima>
            {/* <BackgroundImage image="/img/home-background.jpg" /> */}
            <Box backgroundColor="#A47B78" delay="0s"></Box>
            <Box backgroundColor="#FF46E2" delay="0.5s"></Box>
            <Box backgroundColor="#A419DE" delay="1s"></Box>
            <Container>

                <ContentContainer>
                    <LogoContainer>
                        <Logo src='/svg/logo-together.svg' />
                    </LogoContainer>
                    {/* <Title>Poipoia</Title> */}
                    <Text>Kia ora e te whānau,<br />let's design your own <br />personal greetings in Māori.</Text>

                    <Button>
                        <Link to="/about">
                            <ButtonContainer>
                                <IconTextContainer>
                                    <Icon src="/svg/home-about-icon-circle.svg" />
                                    <ButtonText>Mō tēnei rauemi <ButtonTextSpan>(About)</ButtonTextSpan></ButtonText>
                                </IconTextContainer>
                                <ArroRight src="/svg/arrow-right.svg" />
                            </ButtonContainer>
                        </Link>
                    </Button>

                    <Button>
                        <Link to="/pepeha/0/0">
                            <ButtonContainer>
                                <IconTextContainer>
                                    <Icon src="/svg/pepeha-home.svg" />
                                    <ButtonText>Pepeha <ButtonTextSpan>(Provenance)</ButtonTextSpan></ButtonText>
                                </IconTextContainer>
                                <ArroRight src="/svg/arrow-right.svg" />
                            </ButtonContainer>
                        </Link>
                    </Button>

                    <Button>
                        <Link to="/mihimihi/0/0">
                            <ButtonContainer>
                                <IconTextContainer>
                                    <Icon src="/svg/mihimihi-home.svg" />
                                    <ButtonText>Mihi <ButtonTextSpan>(Greetings)</ButtonTextSpan></ButtonText>
                                </IconTextContainer>
                                <ArroRight src="/svg/arrow-right.svg" />
                            </ButtonContainer>
                        </Link>
                    </Button>

                    <Button>
                        <Link to="/whakapapa/0/0">
                            <ButtonContainer>
                                <IconTextContainer>
                                    <Icon src="/svg/whakapapa-home.svg" />
                                    <ButtonText>Whakapapa <ButtonTextSpan>(Genealogy)</ButtonTextSpan></ButtonText>
                                </IconTextContainer>
                                <ArroRight src="/svg/arrow-right.svg" />
                            </ButtonContainer>
                        </Link>
                    </Button>

                </ContentContainer>
            </Container>

        </ContainerAnima >
    )
}

export default Home;