import React from 'react';
import WordContainer from './WordContainer';

const P = ({ active }) => {

    return (
        active === "p" &&
        <div>
            <WordContainer
                text="pāpā"
                file="papa.mp3"
                translation="father"
            />
            <WordContainer
                text="pepeha"
                file="pepeha.mp3"
                translation="provenance, personal statement"
            />
            <WordContainer
                text="poipoia"
                file="poipoia.mp3"
                translation="nurture, promote"

            />
            <WordContainer
                text="pōkarekare"
                file="pokarekare.mp3"
                translation="ripple"

            />
            {/* <WordContainer
                text="pōkarekare ana"
                file="pokarekare_ana.mp3"
                translation=""

            /> */}
            <WordContainer
                text="puke"
                file="puke.mp3"
                translation="hill"
                border
            />
        </div>
    )
}

export default P;