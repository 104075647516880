import React from 'react';
import WordContainer from './WordContainer';

const R = ({ active }) => {

    return (
        active === "r" &&
        <div>
            <WordContainer
                text="rangatira"
                file="rangatira.mp3"
                translation="leader"
            />
            <WordContainer
                text="rāua"
                file="raua.mp3"
                translation="those two"
            />
            <WordContainer
                text="rauemi"
                file="rauemi.mp3"
                translation="resource"

            />
            {/* <WordContainer
                text="reira"
                file="reira.mp3"
                translation=""

            /> */}
            <WordContainer
                text="rohe"
                file="rohe.mp3"
                translation="district"

            />
            <WordContainer
                text="roto"
                file="roto.mp3"
                translation="in, inside"
                border
            />
        </div>
    )
}

export default R;