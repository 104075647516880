import React from 'react';
import styled from 'styled-components'
import ButtonTapPlay from '../ButtonTapPlay';
import SoundIcon from '../SoundIcon';

const OutContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 30px;
`;

const TextContainer = styled.div`
    @media only screen and (max-width: 768px) {
        margin: 0 20px;;
    }

    @media only screen and (min-width: 768px) {
        width: 380px;
    }
`;

const SubTitle = styled.p`
    font-size: 20px;
    margin: 50px 0 30px 0;
`;




const S3_URL = "https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/whakahua/Vowels/"

const Vowels = () => {

    return (
        <OutContainer>
            <div>
                <TextContainer>
                    <p>
                        There are five vowels sounds in the Māori language.
                        They can be pronounced short or long.
                        We mark the long vowel with a macron, for example, ā.
                        Click in the vowels to hear the correct pronunciation.
                    </p>
                </TextContainer>

                <SubTitle>Short vowels <span><SoundIcon /></span></SubTitle>
                <ButtonTapPlay text="a" url={`${S3_URL}a.mp3`} />
                <ButtonTapPlay text="e" url={`${S3_URL}e.mp3`} />
                <ButtonTapPlay text="i" url={`${S3_URL}i.mp3`} />
                <ButtonTapPlay text="o" url={`${S3_URL}o.mp3`} />
                <ButtonTapPlay text="u" url={`${S3_URL}u.mp3`} />

                <SubTitle>Long vowels <span><SoundIcon /></span></SubTitle>
                <ButtonTapPlay text="ā" url={`${S3_URL}aa.mp3`} />
                <ButtonTapPlay text="ē" url={`${S3_URL}ee.mp3`} />
                <ButtonTapPlay text="ī" url={`${S3_URL}ii.mp3`} />
                <ButtonTapPlay text="ō" url={`${S3_URL}oo.mp3`} />
                <ButtonTapPlay text="ū" url={`${S3_URL}uu.mp3`} />
            </div>
        </OutContainer>
    )
}

export default Vowels;