import React from 'react';
import WaiataTextGroup from './WaiataTextGroup'

const LyricOne = () => {

    return (
        <div>
            <WaiataTextGroup
                textMaori="Purea nei e te hau"
                textEng="Restored by the wind"
            />
            <WaiataTextGroup
                textMaori="Horoia e te ua"
                textEng="washed by the rain"
            />
            <WaiataTextGroup
                textMaori="Whitiwhitia e te rā"
                textEng="and transformed by the sun,"
            />
            <WaiataTextGroup
                textMaori="Mahea ake ngā poraruraru"
                textEng="all doubts are swept away"
            />
            <WaiataTextGroup
                textMaori="Makere ana ngā here."
                textEng="and all restraints are cast down."
            />
            <WaiataTextGroup
                textMaori="E rere wairua, e rere"
                textEng="Fly O free spirit, fly"
            />
            <WaiataTextGroup
                textMaori="Ki ngā ao o te rangi"
                textEng="to the clouds in the heavens,"
            />
            <WaiataTextGroup
                textMaori="Whitiwhitia e te rā"
                textEng="transformed by the sun,"
            />
            <WaiataTextGroup
                textMaori="Mahea ake ngā poraruraru"
                textEng="with all doubts swept away"
            />
            <WaiataTextGroup
                textMaori="Makere ana ngā here"
                textEng="and all restraints cast down."
            />
            <WaiataTextGroup
                textMaori="Makere ana ngā here."
                textEng="Yes, all restraints are cast down."
            />
        </div>

    )
}

export default LyricOne;