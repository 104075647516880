export const sMother = (input) => {
    return [
        `Ko ${input || "..."} taku māmā`,
        `My mother is ${input || "..."}`
    ]
}

export const sFather = (input) => {
    return [
        `Ko ${input || "..."} taku pāpā`,
        `My father is ${input || "..."}`
    ]
}

export const sPartner = (input) => {
    return [
        `Ko ${input || "..."} taku whaiāipo`,
        `My partner is ${input || "..."}`
    ]
}

export const sChild = (input) => {
    return [
        `Ko ${input || "..."} taku tamaiti`,
        `My child is  ${input || "..."}`
    ]
}

export const sTwoChildren = (input) => {
    return [
        `Ko ${input[0].name || "..."} rāua ko ${input[1].name || "..."}`,
        `My two children are ${input[0].name || "..."} and ${input[1].name || "..."}`
    ]
}

export const sMaternalGrandFather = (input) => {
    return [
        `I te taha o taku  māmā, ko ${input || "..."} taku koroua`,
        `On my mother's side, my grandfather is ${input || "..."}`
    ]
}

export const sMaternalGrandMother = (input) => {
    return [
        `I te taha o taku māmā, ko ${input || "..."} taku kuia`,
        `On my mother's side, my grandmother is ${input || "..."}`
    ]
}

export const sPaternalGrandFather = (input) => {
    return [
        `I te taha o taku pāpā, ko ${input || "..."} taku koroua`,
        `On my father's side, my grandfather is ${input || "..."}`
    ]
}

export const sPaternalGrandMother = (input) => {
    return [
        `I te taha o taku pāpā, ko ${input || "..."} taku kuia`,
        `On my father's side, my grandmother is ${input || "..."}`
    ]
}