import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components'

const Button = styled.div`
    text-align: center;
`;

const Icon = styled.img` 

`;

const ButtonText = styled.p`
    color: white;       
    font-size: 12px;
`;

const ButtonTextSpan = styled.p`       
    color: #89A1AD;
    font-size: 10px;
    margin-top: -12px;

`;


const ButtonMenu = ({ link, image, maoriText, engText }) => {
    return (

        <Button>
            <Link to={link}>
                <Icon src={image} />
                <ButtonText>{maoriText}</ButtonText>
                <ButtonTextSpan>{engText}</ButtonTextSpan>
            </Link>
        </Button>

    )
}

export default ButtonMenu;