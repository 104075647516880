import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from './Button';

const ContainerOut = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Container = styled.div`       
    margin: 50px 0;
    text-align: center;
    padding: 0 30px;
    width: 100%;

    @media only screen and (min-width: 450px) {
        width: 400px;
    }
`;

const TitleStyled = styled.h1`
    color: ${props => props.color};
    font-size: 20px;
    margin: 5px 0 30px 0;

    @media only screen and (min-width: 768px) {
        font-size: 30px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 30px;
    }
`;

const IntroContainer = styled.div`
    margin: 20px 0 30px 0;

    @media only screen and (min-width: 1200px) {
        margin: 20px 0 40px 0;
    }
`;


const SectionIntro = ({ imgSrc, intro, btText, title, path, toPrintTheme }) => {
    return (
        <ContainerOut>
            <Container>
                {imgSrc && <img src={imgSrc} alt={title} />}
                <TitleStyled color={toPrintTheme ? "#2F373B" : "#ffffff"} >{title}</TitleStyled>
                <IntroContainer>
                    {intro}
                </IntroContainer>
                {
                    btText &&
                    <Link to={path}>
                        <Button text={btText} />
                    </Link>
                }
            </Container>
        </ContainerOut>
    )
}

export default SectionIntro;