import React, { useEffect, useState, useContext } from 'react';
import { Analytics } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../components/Button';
import Image from '../components/Image'
import SectionIntro from '../components/SectionIntro';
import SectionIntroHandlePopUp from '../components/SectionIntroHandlePopUp';
import PopUpCard from '../components/PopUpCard';
import WhakapapaFlow from '../components/whakapapa/WhakapapaFlow'

const WhakapapaContainer = styled.div`
    margin-top: 55px;
`;

const IntroContainer = styled.div`
    // @media only screen and (min-width: 1200px) {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     height: 90vh;
    // }
`;

const PopUpContent = ({ setShowPopUp }) => {


    return (
        <div>
            <h1>What is whakapapa?</h1>
            <p>
                Whakapapa is used to represent whānau relationships, who your whānau is and how you are all related. Simply select the sections that are relevant to you and your whānau.
                When you're finished your whakapapa will be available for you to view on this device, nō reira, toru, whā, KARAWHIUA!'
            </p>
            <div onClick={() => setShowPopUp(false)}>
                <Button text="Tēna, got it" />
            </div>
        </div>
    )
}

const Whakapapa = () => {
    const [showPopUp, setShowPopUp] = useState(false)
    const [toPrintTheme, setToPrintTheme] = useState(true)
    const [mother, setMother] = useState("")
    const [father, setFather] = useState("")
    const [partner, setPartner] = useState("")
    const [child, setChild] = useState("")

    const [twoChildren, setTwoChildren] = useState([{ name: "" }, { name: "" }])
    const [threeChildren, setThreeChildren] = useState([{ name: "" }, { name: "" }, { name: "" }])

    const [maternalGrandFather, setMaternalGrandFather] = useState("")
    const [maternalGrandMother, setMaternalGrandMother] = useState("")
    const [paternalGrandFather, setPaternalGrandFather] = useState("")
    const [paternalGrandMother, setPaternalGrandMother] = useState("")

    const [childrenNumber, setChildrenNumber] = useState("init")

    useEffect(() => {
        Analytics.record({
            name: "Whakapapa page visit"
        })
    }, [])

    let { whakapapaView } = useParams()

    // if (showPopUp) {
    //     return <PopUpCard
    //         setShowPopUp={setShowPopUp}
    //         cardContent={<PopUpContent setShowPopUp={setShowPopUp} />}
    //         withCloseButton={true}
    //     />
    // }

    if (whakapapaView) {
        if (whakapapaView == "0") {
            if (mother) setMother("")
            if (father) setFather("")
            if (partner) setPartner("")
            if (child) setChild("")
            if (maternalGrandFather) setMaternalGrandMother("")
            if (maternalGrandMother) setMaternalGrandMother("")
            if (paternalGrandFather) setPaternalGrandFather("")
            if (paternalGrandMother) setPaternalGrandMother("")
        }
        return (
            <>
                {showPopUp &&
                    <PopUpCard
                        setShowPopUp={setShowPopUp}
                        cardContent={<PopUpContent setShowPopUp={setShowPopUp} />}
                        withCloseButton={true}
                    />
                }
                <WhakapapaContainer>
                    {
                        whakapapaView === "0" &&
                        <IntroContainer>
                            <Image image="/img/whakapapa.jpg" />
                            < SectionIntro
                                imgSrc="/svg/whakapapa-red.svg"
                                title="Your whakapapa"
                                toPrintTheme={toPrintTheme}
                                intro={
                                    <SectionIntroHandlePopUp
                                        setShowPopUp={setShowPopUp}
                                        text="Your whakapapa allows you to share who your whānau members are.
                                    Like pepeha, it gives others an opportunity to connect with you,
                                    to appreciate who you are and the special people in your life."
                                    />
                                }
                                btText="Karawhiua - Let's start"
                                path="/whakapapa/1/1"
                            />
                        </IntroContainer>
                    }
                    {console.log(childrenNumber)}

                    {
                        whakapapaView === "1" &&


                        <WhakapapaFlow
                            inputValueMother={mother}
                            setInputStateMother={setMother}
                            inputValueFather={father}
                            setInputStateFather={setFather}
                            inputValuePartner={partner}
                            setInputStatePartner={setPartner}



                            inputValueChild={child}
                            setInputValueChild={setChild}

                            inputValueTwoChildren={twoChildren}
                            setInputValueTwoChildren={setTwoChildren}

                            inputValueThreeChildren={threeChildren}
                            setInputValueThreeChildren={setThreeChildren}

                            inputValueMaternalGrandFather={maternalGrandFather}
                            setInputValueMaternalGrandFather={setMaternalGrandFather}

                            inputValueMaternalGrandMother={maternalGrandMother}
                            setInputValueMaternalGrandMother={setMaternalGrandMother}

                            inputValuePaternalGrandFather={paternalGrandFather}
                            setInputValuePaternalGrandFather={setPaternalGrandFather}

                            inputValuePaternalGrandMother={paternalGrandMother}
                            setInputValuePaternalGrandMother={setPaternalGrandMother}

                            childrenNumber={childrenNumber}
                            setChildrenNumber={setChildrenNumber}
                        />

                    }
                </WhakapapaContainer>
            </>
        )
    }

}

export default Whakapapa;