import React from 'react';
import KarakiaTextGroup from './KarakiaTextGroup'

const LyricSix = () => {

    return (
        <div>
            <KarakiaTextGroup
                textMaori="Whakataka te hau ki te uru"
                textEng="Cease the winds from the west"
            />
            <KarakiaTextGroup
                textMaori="Whakataka te hau ki te tonga"
                textEng="Cease the winds from the south"
            />
            <KarakiaTextGroup
                textMaori="Kia mākinakina ki uta"
                textEng="Let the breeze blow over the land"
            />
            <KarakiaTextGroup
                textMaori="Kia mātaratara ki tai"
                textEng="Let the breeze blow over the ocean"
            />
            <KarakiaTextGroup
                textMaori="E hī ake ana te atakura"
                textEng="Let the red-tipped dawn come with a sharpened air"
            />
            <KarakiaTextGroup
                textMaori="He tio, he huka, he hau hū"
                textEng="A touch of frost"
            />
            <KarakiaTextGroup
                textMaori="Tīhei mauri ora!"
                textEng="a promise of a glorious day"
            />
        </div>
    )
}

export default LyricSix;