import React from 'react';
import WordContainer from './WordContainer';

const I = ({ active }) => {

    return (
        active === "i" &&
        <div>
            {/* <WordContainer
                text="i"
                file="i.mp3"
                translation="English translation"
            /> */}
            <WordContainer
                text="ingoa"
                file="ignoa.mp3"
                translation="name"
            />
            <WordContainer
                text="iwi"
                file="iwi.mp3"
                translation="tribe, people"
                border
            />
        </div>
    )
}

export default I;