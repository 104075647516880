import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;
`;

const Great = ({
    setInputState,
    inputValue
}) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='koe'
                engText="to you (singular)"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "koe" ? true : false}
            />
            <ButtonChoice
                maoriText="kōrua"
                engText="to you both"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "kōrua" ? true : false}
            />
            <ButtonChoice
                maoriText="koutou"
                engText="to you (you and your friends)"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "koutou" ? true : false}
            />
            <ButtonChoice
                maoriText="ahau"
                engText="I (singular)"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "ahau" ? true : false}
            />
            <ButtonChoice
                maoriText="mātou"
                engText="we (me and my friends)"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "mātou" ? true : false}
            />
            <ButtonChoice
                maoriText="tātou"
                engText="all of us (all inclusive)"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "tātou" ? true : false}
            />
        </ButtonContainer>
    )
}

export default Great;