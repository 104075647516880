import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Title from './Title'
import Button from './Button';
import Input from './Input';


const Container = styled.div`
    margin: 0 20px;
    text-align: center;
    width: 280px;

    @media only screen and (min-width: 768px) {
        width: 400px;
    }
`;

const Text = styled.p`
    font-size: 12px;
    margin: -25px 0 30px 0;

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 20px;
    }
`;


const InputQuestion = ({ imgSrc, title, text, inputValue, setInputState, placeholder, path }) => {
    return (

        <Container>
            <img src={imgSrc} alt={title} />
            <Title title={title} />
            <Text>{text}</Text>
            <Input
                inputValue={inputValue}
                setInputState={setInputState}
                placeholder={placeholder}
            />
            <Link to={path}>
                <Button text="Next" />
            </Link>
        </Container>

    )
}

export default InputQuestion;