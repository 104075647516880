import React from 'react';
import WordContainer from './WordContainer';

const E = ({ active }) => {

    return (
        active === "e" &&
        <div>
            {/* <WordContainer
                text="e"
                file="e.mp3"
                translation="English translation"
            /> */}
            <WordContainer
                text="e aku rangatira"
                file="e_aku_rangatira.mp3"
                translation="my leader"
            />
            <WordContainer
                text="e hoa"
                file="e_hoa.mp3"
                translation="dear friend"
            />

            <WordContainer
                text="e hoa mā"
                file="e_hoa_ma.mp3"
                translation="dear friends"
            />
            <WordContainer
                text="e te hau kāinga"
                file="e_te-hau-kainga.mp3"
                translation="to the hosts"
            />
            <WordContainer
                text="e te manuhiri"
                file="e_te_manuhiri.mp3"
                translation="to the guests"
            />
            <WordContainer
                text="e te whānau"
                file="e_te_whanau.mp3"
                translation="to the family"
            />
            <WordContainer
                text="e tipu e rea"
                file="e_tipo_e_rea.mp3"
                translation="grow young seedling (child)"
            />
            <WordContainer
                text="ehara"
                file="ehara.mp3"
                translation="It is not, does not"
            />
            <WordContainer
                text="ehara i te mea"
                file="ehara_i_te_mea.mp3"
                translation="It is not as if..."
                border
            />
        </div>
    )
}

export default E;