import React from 'react';
import WordContainer from './WordContainer';

const N = ({ active }) => {

    return (
        active === "n" &&
        <div>
            <WordContainer
                text="ngā"
                file="nga.mp3"
                translation="the (plural)"
            />
            <WordContainer
                text="ngā kūoro"
                file="nga_kuoro.mp3"
                translation="syllables"
            />
            <WordContainer
                text="ngā orokati"
                file="nga_orokati.mp3"
                translation="consonants"
            />
            <WordContainer
                text="ngā oropuare"
                file="nga_oropuare.mp3"
                translation="vowels"
            />
            <WordContainer
                text="ngāwari"
                file="ngawari.mp3"
                translation="easy"
            />
            <WordContainer
                text="nō"
                file="no.mp3"
                translation="from"
            />
            <WordContainer
                text="nō reira"
                file="no_reira.mp3"
                translation="therefore"
            />
            <WordContainer
                text="noho"
                file="noho.mp3"
                translation="sit, stay"
                border
            />
        </div>
    )
}

export default N;