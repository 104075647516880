import React from 'react';
import styled from 'styled-components'

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ImageStyled = styled.img`
  display: inherit;
  object-fit: cover;
  height: 35vh;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: 45vh;
    }

  @media only screen and (min-width: 1200px) {
    height: 35vh;
    width: 600px;
    }
`;


const Image = ({ image }) => {
    return (
        <ImageContainer>
            <ImageStyled src={image} alt="" />
        </ImageContainer>
    )
}

export default Image;