import React from 'react';
import KarakiaTextGroup from './KarakiaTextGroup'

const LyricThree = () => {

    return (
        <div>
            <KarakiaTextGroup
                textMaori="Nau mai e ngā hua"
                textEng="Bequeath the gifts"
            />
            <KarakiaTextGroup
                textMaori="Nā Tāne"
                textEng="of Tāne"
            />
            <KarakiaTextGroup
                textMaori="Nā Rongo"
                textEng="of Rongo"
            />
            <KarakiaTextGroup
                textMaori="Nā Tangaroa"
                textEng="of Tangaroa"
            />
            <KarakiaTextGroup
                textMaori="Nā Maru"
                textEng="of Maru"
            />
            <KarakiaTextGroup
                textMaori="Whītiki ki te ora"
                textEng="life giving sustenance"
            />
            <KarakiaTextGroup
                textMaori="Whakamaua kia tīna"
                textEng="Let it be realised"
            />
            <KarakiaTextGroup
                textMaori="Hui e Tāiki e"
                textEng="for us all"
            />

        </div>
    )
}

export default LyricThree;