import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;
`;

const ToB = ({
    setInputState,
    inputValue
}) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='whakarite'
                engText="determine"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'whakarite' ? true : false}
            />
            <ButtonChoice
                maoriText='kawe mai'
                engText="bring"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'kawe mai' ? true : false}
            />
            <ButtonChoice
                maoriText='kōrero'
                engText="discuss"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'kōrero' ? true : false}
            />
            <ButtonChoice
                maoriText='wānanga'
                engText="solve"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'wānanga' ? true : false}
            />
            <ButtonChoice
                maoriText='whāriki'
                engText="lay down"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'whāriki' ? true : false}
            />
            <ButtonChoice
                maoriText='rongo'
                engText="hear"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'rongo' ? true : false}
            />
        </ButtonContainer>
    )
}

export default ToB;