import React from 'react';
import styled from 'styled-components'

const TitleStyled = styled.p`
    color: ${props => props.colorTextMaori};
    font-family: MediumFont;
    font-size: 20px;
`;

const Text = styled.p`
    color: ${props => props.colorTextEng};
    margin-top: -18px;
    font-size: 15px;
`;


const TextToPrintThreeChildren = ({ inputValueThreeChildren, toPrintTheme }) => {

    return (
        <>
            <TitleStyled colorTextMaori={toPrintTheme ? "#2F373B" : "#FFFFFF"}>{`Ko ${inputValueThreeChildren[0].name || "..."} rātou`}</TitleStyled>
            <Text colorTextEng={toPrintTheme ? "#2F373B" : "#FFFFFF"}>{`My children are ${inputValueThreeChildren[0].name || "..."} and`}</Text>
            {inputValueThreeChildren.map((element, index) => {
                if (index > 0) return (
                    <div>
                        <TitleStyled colorTextMaori={toPrintTheme ? "#2F373B" : "#FFFFFF"} key={index}>{`Ko ${element.name || "..."}`}</TitleStyled>
                        <Text colorTextEng={toPrintTheme ? "#2F373B" : "#FFFFFF"} key={index}>{`${element.name || "..."}`}</Text>
                    </div>
                )


            })}
            <TitleStyled colorTextMaori={toPrintTheme ? "#2F373B" : "#FFFFFF"}>aku tamariki</TitleStyled>
            <Text colorTextEng={toPrintTheme ? "#2F373B" : "#FFFFFF"}>are my children</Text>
        </>
    )
}

export default TextToPrintThreeChildren;