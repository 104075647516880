import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Image from '../components/Image'
import KarakiaButtons from '../components/Karakia/KarakiaButtons';
import KarakiaView from '../components/Karakia/KarakiaView';
import LyricOne from '../components/Karakia/LyricOne';
import LyricTwo from '../components/Karakia/LyricTwo';
import LyricThree from '../components/Karakia/LyricThree';
import LyricFour from '../components/Karakia/LyricFour';
import LyricFive from '../components/Karakia/LyricFive';
import LyricSix from '../components/Karakia/LyricSix';
import LyricSeven from '../components/Karakia/LyricSeven';
import LyricEight from '../components/Karakia/LyricEight';
import Title from '../components/Title'


const WhakapapaContainer = styled.div`
    margin-top: 55px;
`;

const IntroContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const SubTitle = styled.p`
    margin-top: -30px;
`;

const Content = styled.div`
    margin-top: 30px;
`;

const URL = "https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/waiata/"

const Karakia = () => {
    let { view } = useParams()

    useEffect(() => {
        Analytics.record({
            name: "Karakia page visit"
        })
    }, [])

    if (view) {
        return (
            <WhakapapaContainer>
                {
                    view === "0" &&
                    <IntroContainer>
                        <Image image="/img/karakia.jpg" />
                        <Content>
                            <Title title="Karakia" />
                            {/* <SubTitle>Listen and learn Karakia (prayers) </SubTitle> */}
                            <KarakiaButtons />
                        </Content>
                    </IntroContainer>
                }

                {
                    view === "1" &&
                    <KarakiaView
                        title="Tuia"
                        // subTitle="Karakia - For beginning and Closing"
                        song={`${URL}tuia.mp3`}
                        lyric={<LyricOne />}
                    />
                }

                {
                    view === "2" &&
                    <KarakiaView
                        title="Ūhia mai rā"
                        // subTitle="Karakia - For beginning and Closing"
                        song={`${URL}uhia.mp3`}
                        lyric={<LyricTwo />}
                    />
                }


                {
                    view === "3" &&
                    <KarakiaView
                        title="Nau mai (kai)"
                        // subTitle="Before food"
                        song={`${URL}nau_mai.mp3`}
                        lyric={<LyricThree />}
                    />
                }

                {
                    view === "4" &&
                    <KarakiaView
                        title="Kia tau iho"
                        // subTitle="Karakia - For beginning and Closing"
                        song={`${URL}kia_tau_iho.mp3`}
                        lyric={<LyricFour />}
                    />
                }

                {
                    view === "5" &&
                    <KarakiaView
                        title="Mauri oho"
                        // subTitle="Karakia timatanga - For beginning, starting, opening"
                        song={`${URL}mauri_oho.mp3`}
                        lyric={<LyricFive />}
                    />
                }

                {
                    view === "6" &&
                    <KarakiaView
                        title="Whakataka te Hau"
                        // subTitle="Karakia timatanga - For beginning, starting, opening"
                        song={`${URL}whakataka_te_hau.mp3`}
                        lyric={<LyricSix />}
                    />
                }

                {
                    view === "7" &&
                    <KarakiaView
                        title="Kia tau"
                        // subTitle="Karakia whakamutunga - For closing"
                        song={`${URL}kia_tau_ki_a_tatou_katoa.mp3`}
                        lyric={<LyricSeven />}
                    />
                }

                {
                    view === "8" &&
                    <KarakiaView
                        title="Unuhia"
                        subTitle="Karakia whakamutunga - For closing"
                        song={`${URL}unuhia.mp3`}
                        lyric={<LyricEight />}
                    />
                }
            </WhakapapaContainer>
        )
    }

}

export default Karakia;