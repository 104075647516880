import React from 'react';
import styled from 'styled-components'
import TextToPrint from '../TextToPrint'
import InvertColor from '../InvertColor';

const Container = styled.div`
    margin-top: -50px;
    text-align: center;
    padding: 0 10px 50px 10px;
`;

const IsMaoriPepehaToPrintUi = ({
    greetingsTo,
    greetingsToCont,
    i,
    iamFeeling,
    personalPronoun,
    therefore,
    to,
    toB,
    whoHave,
    saying,
    great,
    mo,
    kite,
    these,
    concerning,
    setToPrintTheme,
    toPrintTheme
}) => {

    return (
        <Container>
            {
                greetingsTo !== "" &&
                <TextToPrint
                    textMaori={`Tēnā ${greetingsTo[0]}`}
                    textEng={`Greetings to ${greetingsTo[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                greetingsToCont !== "" &&
                <TextToPrint
                    textMaori={`${greetingsToCont[0]}`}
                    textEng={`${greetingsToCont[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                iamFeeling !== "" &&
                <TextToPrint
                    textMaori={`Kei te ${iamFeeling[0]} ahau`}
                    textEng={`I am feeling ${iamFeeling[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                whoHave !== "" &&
                <TextToPrint
                    textMaori={`kua ${whoHave[0]}`}
                    textEng={`who have ${whoHave[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            }

            {/* {
                to !== "" &&
                <TextToPrint
                    textMaori={`ki ${to[0]}`}
                    textEng={`to ${to[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            } */}

            {
                to !== "" &&
                <TextToPrint
                    textMaori={`ki ${to[0] ? to[0] : ""}`}
                    textEng={`to ${to[1] ? to[1] : ""}`}
                    toPrintTheme={toPrintTheme}
                />
            }
            {
                toB !== "" &&
                <TextToPrint
                    textMaori={`e ${toB[0]} ana ${personalPronoun[0] ? personalPronoun[0] : ""}`}
                    textEng={`to ${toB[1]} ${personalPronoun[1] ? personalPronoun[1] : ""}`}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                i !== "" &&
                <TextToPrint
                    textMaori={`i ${i[0]}`}
                    textEng={`${i[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            }




            {
                saying !== "" &&
                <>
                    <TextToPrint
                        textMaori={`E kiia nei te kōrero`}
                        textEng={`The saying goes`}
                        toPrintTheme={toPrintTheme}
                    />

                    <TextToPrint
                        textMaori={`${saying[0]}`}
                        textEng={`${saying[1]}`}
                        toPrintTheme={toPrintTheme}
                    />
                </>
            }

            {
                great !== "" &&
                <TextToPrint
                    textMaori={`Nā konā, kei a ${great[0]}`}
                    textEng={`As such ${great[1]} have great`}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                mo !== "" &&
                <TextToPrint
                    textMaori={`mō te ${mo[0]}`}
                    textEng={` ${mo[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                kite !== "" &&
                <TextToPrint
                    textMaori={`Ki te ${kite[0]}`}
                    textEng={`to ${kite[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                these !== "" &&
                <TextToPrint
                    textMaori={`i ${these[0]}`}
                    textEng={`this/these ${these[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                concerning !== "" &&
                <TextToPrint
                    textMaori={`e pā ana ki ${concerning[0]}`}
                    textEng={`concerning ${concerning[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            }

            {/* {
                i !== "" &&
                <TextToPrint
                    textMaori={`i ${i[0]}`}
                    textEng={`i ${i[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            } */}

            {
                therefore !== "" &&
                <TextToPrint
                    textMaori={`nō reira ${therefore[0]}`}
                    textEng={`therefore ${therefore[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            }


            {
                greetingsTo !== "" &&
                <TextToPrint
                    textMaori={`Tēnā ${greetingsTo[0]}`}
                    textEng={`Greetings to ${greetingsTo[1]}`}
                    toPrintTheme={toPrintTheme}
                />
            }

            <InvertColor setToPrintTheme={setToPrintTheme} toPrintTheme={toPrintTheme} />
        </Container>
    )
}

export default IsMaoriPepehaToPrintUi;