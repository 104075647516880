import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;
`;

const WhoHave = ({
    setInputState,
    inputValue
}) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='tae mai nei'
                engText="arrived here"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'tae mai nei' ? true : false}
            />
            <ButtonChoice
                maoriText='huihui mai nei'
                engText="gathered here"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'huihui mai nei' ? true : false}
            />
            <ButtonChoice
                maoriText='haere mai nei'
                engText="come here"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'haere mai nei' ? true : false}
            />
            <ButtonChoice
                maoriText='eke mai nei'
                engText="arrived upon"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'eke mai nei' ? true : false}
            />
            <ButtonChoice
                maoriText='pōwhiri mai nei'
                engText="welcome me/us"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'pōwhiri mai nei' ? true : false}
            />
            <ButtonChoice
                maoriText='karanga mai nei'
                engText="called to me/us"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'karanga mai nei' ? true : false}
            />
        </ButtonContainer>
    )
}

export default WhoHave;