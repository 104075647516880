import React from 'react'
import styled from 'styled-components'

const PopUpContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: center;
    align-items: center;

    margin-top: -55px;

    background-color: rgb(45,55,60, 0.9);
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 102;
`;

const Card = styled.div`
    background-color: #FFFFFF;
    border-radius: 15px;
    width: 325px;

    @media only screen and (min-width: 768px) {
        width: 367px;
    }
`;

const CloseCard = styled.img`
    cursor: pointer;
    float: right;
    margin: 20px;
`;

const ContentContainer = styled.div`
    margin: 70px 20px 20px 20px;
    text-align: center;
`;

const PopUpCard = ({ setShowPopUp, cardContent, withCloseButton }) => {
    return (
        <PopUpContainer>
            <Card>
                {
                    withCloseButton &&
                    <CloseCard onClick={() => setShowPopUp(false)} src='/svg/card-close.svg' alt="" />
                }
                <ContentContainer>
                    {cardContent}
                </ContentContainer>
            </Card>
        </PopUpContainer>
    )
}


export default PopUpCard