import React, { useState, useEffect } from "react";
import styled from 'styled-components'

const Button = styled.button`
    background-color: white;
    border: 2px solid #D5012B;
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    color: #D5012B;
    cursor: pointer;
    border-radius: 50%;
    font-size: 18px;
    font-family: MediumFont;
    margin-left: 10px;
    width: 56px;
    height: 56px;

    :hover {
        background-color: #D5012B;
        color: white;
      }
`;

const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
};


const ButtonTapPlay = ({ url, text }) => {
    const [playing, toggle] = useAudio(url);

    return (
        <Button onClick={toggle}>{text}</Button>
    )
}

export default ButtonTapPlay;