import React from 'react';
import WordContainer from './WordContainer';

const K = ({ active }) => {

    return (
        active === "k" &&
        <div>
            <WordContainer
                text="ka"
                file="ka.mp3"
                translation="(particle)"
            />
            <WordContainer
                text="ka mate"
                file="ka_mate.mp3"
                translation="to perish, to be unwell"
            />
            <WordContainer
                text="kai"
                file="kai.mp3"
                translation="food"
            />
            <WordContainer
                text="kāinga"
                file="kainga.mp3"
                translation="home"
            />
            <WordContainer
                text="karakia"
                file="karakia.mp3"
                translation="blessing"
            />
            <WordContainer
                text="karakia mō te kai"
                file="karakia_mo_te_kai.mp3"
                translation="blessing of food"
            />
            <WordContainer
                text="karakia timatanga"
                file="karakia_timatanga.mp3"
                translation="opening blessing"
            />
            <WordContainer
                text="karawhiua"
                file="karawhiua.mp3"
                translation="go for it"
            />
            <WordContainer
                text="katoa"
                file="katoa.mp3"
                translation="all"
            />
            <WordContainer
                text="kia ora"
                file="kia_ora.mp3"
                translation="hello, thank you"
            />
            {/* <WordContainer
                text="ko"
                file="ko.mp3"
                translation="English translation"
            /> */}
            <WordContainer
                text="koe"
                file="koe.mp3"
                translation="you"
            />
            <WordContainer
                text="koroua"
                file="koroua.mp3"
                translation="elderly man"
            />
            <WordContainer
                text="kōrua"
                file="korua.mp3"
                translation="you two"
            />
            <WordContainer
                text="koutou"
                file="koutou.mp3"
                translation="you three or more"
            />
            <WordContainer
                text="koutou katoa"
                file="koutou_katoa.mp3"
                translation="you (plural) all"
            />
            <WordContainer
                text="kūoro"
                file="kuoro.mp3"
                translation="syllable"
            />
            <WordContainer
                text="kupu"
                file="kupu.mp3"
                translation="word"
            />
            <WordContainer
                text="kupu taka"
                file="kupu_taka.mp3"
                translation="glossary"
                boder
            />
        </div>
    )
}

export default K;