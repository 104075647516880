import React from 'react';
import styled from 'styled-components'
import TextToPrint from '../TextToPrint'
import InvertColor from '../InvertColor';

import {
    whereBorn,
    whereLiving,
    ancestors,
    whanau,
    name,
    mountain,
    hill,
    sea,
    river,
    lake,
    tribe
} from './IsNotMaoriPepehaSentences';

const Container = styled.div`
    margin-top: -50px;
    text-align: center;
    padding: 0 10px 50px 10px;
`;

const IsNotMaoriPepehaToPrintUi = ({
    inputValueAncestors,
    inputValueHill,
    inputValueMountain,
    inputValueRiver,
    inputValueLake,
    inputValueSea,
    inputValueTribe,
    inputValueName,
    inputValueWhereBorn,
    inputValueWhereLiving,
    inputValueWhanau,
    toPrintTheme,
    setToPrintTheme,
    isBasic
}) => {

    return (
        <Container>
            {
                isBasic == true &&
            <div>
                {inputValueAncestors !== "" &&
                <TextToPrint
                    textMaori={ancestors(inputValueAncestors)[0]}
                    textEng={ancestors(inputValueAncestors)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
                {inputValueWhanau !== "" &&
                <TextToPrint
                    textMaori={whanau(inputValueWhanau)[0]}
                    textEng={whanau(inputValueWhanau)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
                {inputValueWhereBorn !== "" &&
                <TextToPrint
                    textMaori={whereBorn(inputValueWhereBorn)[0]}
                    textEng={whereBorn(inputValueWhereBorn)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
                {inputValueWhereLiving !== "" &&
                <TextToPrint
                    textMaori={whereLiving(inputValueWhereLiving)[0]}
                    textEng={whereLiving(inputValueWhereLiving)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
                {inputValueName !== "" &&
                <TextToPrint
                    textMaori={name(inputValueName)[0]}
                    textEng={name(inputValueName)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
            </div>
            }
            {
                isBasic == false &&
            <div>
                {inputValueAncestors !== "" &&
                <TextToPrint
                    textMaori={ancestors(inputValueAncestors)[0]}
                    textEng={ancestors(inputValueAncestors)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
                {inputValueWhanau !== "" &&
                <TextToPrint
                    textMaori={whanau(inputValueWhanau)[0]}
                    textEng={whanau(inputValueWhanau)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
                {inputValueWhereBorn !== "" &&
                <TextToPrint
                    textMaori={whereBorn(inputValueWhereBorn)[0]}
                    textEng={whereBorn(inputValueWhereBorn)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
                {inputValueMountain !== "" &&
                    <TextToPrint
                        textMaori={mountain(inputValueMountain)[0]}
                        textEng={mountain(inputValueMountain)[1]}
                        toPrintTheme={toPrintTheme}
                    />
                }
                {inputValueHill !== "" &&
                    <TextToPrint
                        textMaori={hill(inputValueHill)[0]}
                        textEng={hill(inputValueHill)[1]}
                        toPrintTheme={toPrintTheme}
                    />
                }
                {inputValueLake !== "" &&
                <TextToPrint
                    textMaori={lake(inputValueLake)[0]}
                    textEng={lake(inputValueLake)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
                {inputValueRiver !== "" &&
                <TextToPrint
                    textMaori={river(inputValueRiver)[0]}
                    textEng={river(inputValueRiver)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
                {inputValueSea !== "" &&
                    <TextToPrint
                        textMaori={sea(inputValueSea)[0]}
                        textEng={sea(inputValueSea)[1]}
                        toPrintTheme={toPrintTheme}
                    />
                }
                {inputValueWhereLiving !== "" &&
                <TextToPrint
                    textMaori={whereLiving(inputValueWhereLiving)[0]}
                    textEng={whereLiving(inputValueWhereLiving)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
                {inputValueTribe !== "" &&
                    <TextToPrint
                        textMaori={tribe(inputValueTribe)[0]}
                        textEng={tribe(inputValueTribe)[1]}
                        toPrintTheme={toPrintTheme}
                    />
                }
                {inputValueName !== "" &&
                <TextToPrint
                    textMaori={name(inputValueName)[0]}
                    textEng={name(inputValueName)[1]}
                    toPrintTheme={toPrintTheme}
                />
                }
            </div>
            }
            <InvertColor setToPrintTheme={setToPrintTheme} toPrintTheme={toPrintTheme} />
        </Container>
    )
}

export default IsNotMaoriPepehaToPrintUi;