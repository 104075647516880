import React from 'react';
import styled from 'styled-components'

import SectionIntro from './SectionIntro'

const ToPrintContainer = styled.div`
    background-color: ${props => props.backgroundColor};
    border: 1px solid #C6C6C6;
    margin-top: 80px;
    width: 100%;
    border-radius: 6px;
`;

const SectionToPrint = ({ imgSrc, title, toPrintUI, toPrintTheme }) => {

    return (
        <>
            <ToPrintContainer backgroundColor={toPrintTheme ? "#FFFFFF" : "#000000"} >
                < SectionIntro
                    imgSrc={imgSrc}
                    title={title}
                    toPrintTheme={toPrintTheme}
                />
                {toPrintUI}
            </ToPrintContainer>
        </>

    )
}

export default SectionToPrint;