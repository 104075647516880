import React from 'react';
import { Document, View, Page, Font, Image, Text, StyleSheet } from '@react-pdf/renderer';
import fontMedium from '../../fonts/Poppins-Medium.ttf'
import fontRegular from '../../fonts/Poppins-Regular.ttf'

import {
    sMother,
    sFather,
    sPartner,
    sChild,
    sTwoChildren,
    sMaternalGrandFather,
    sMaternalGrandMother,
    sPaternalGrandFather,
    sPaternalGrandMother,
} from './WhakapapaSentences'

Font.register({ family: 'PoppinsMedium', src: fontMedium })
Font.register({ family: 'PoppinsRegular', src: fontRegular })

// styling guide https://react-pdf.org/styling
const stylesLight = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    section: {
        color: '#2D373C',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        textAlign: 'center',
        margin: 30,
        width: 500
    },
    maori_text: {
        fontSize: 12,
        fontFamily: 'PoppinsMedium'
    },
    eng_text: {
        fontSize: 10,
        fontFamily: 'PoppinsRegular',
        marginBottom: 12,
    },
    image_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 50,
    },
    image: {
        width: 221,
        height: 70
    }
});

const stylesDark = StyleSheet.create({
    page: {
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    section: {
        color: 'white',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        textAlign: 'center',
        margin: 30,
        width: 500
    },
    maori_text: {
        fontSize: 12,
        fontFamily: 'PoppinsMedium'
    },
    eng_text: {
        fontSize: 10,
        fontFamily: 'PoppinsRegular',
        marginBottom: 12,
    },
    image_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 50,
    },
    image: {
        width: 221,
        height: 70
    }
});

const MaoriText = ({ text, toPrintTheme }) => <Text style={toPrintTheme ? stylesLight.maori_text : stylesDark.maori_text}>{text}</Text>
const EngText = ({ text, toPrintTheme }) => <Text style={toPrintTheme ? stylesLight.eng_text : stylesDark.eng_text}>{text}</Text>

const ThreeChildren = ({ inputValueThreeChildren, toPrintTheme }) => {

    return (
        <>
            <Text style={toPrintTheme ? stylesLight.maori_text : stylesDark.maori_text}>{`Ko ${inputValueThreeChildren[0].name || "..."} rātou`}</Text>
            <Text style={toPrintTheme ? stylesLight.eng_text : stylesDark.eng_text}>{`My children are ${inputValueThreeChildren[0].name || "..."}`}</Text>
            {inputValueThreeChildren.map((element, index) => {
                if (index > 0) return (
                    <div>
                        <Text style={toPrintTheme ? stylesLight.maori_text : stylesDark.maori_text}>{`Ko ${element.name || "..."}`}</Text>
                        <Text style={toPrintTheme ? stylesLight.eng_text : stylesDark.eng_text} key={index}>{`${element.name || "..."}`}</Text>
                    </div>
                )


            })}
            <Text style={toPrintTheme ? stylesLight.maori_text : stylesDark.maori_text}>aku tamariki</Text>
            <Text style={toPrintTheme ? stylesLight.eng_text : stylesDark.eng_text}>are my children</Text>
        </>
    )
}

const IsMaoriPepehaToPrint = ({
    mother,
    father,
    partner,
    child,
    twoChildren,
    threeChildren,
    maternalGrandFather,
    maternalGrandMother,
    paternalGrandFather,
    paternalGrandMother,
    toPrintTheme,

    childrenNumber
}) => (
    <Document>
        <Page size="A4" style={toPrintTheme ? stylesLight.page : stylesDark.page}>
            <View style={toPrintTheme ? stylesLight.section : stylesDark.section}>
                <View style={toPrintTheme ? stylesLight.image_container : stylesDark.image_container}>
                    {
                        toPrintTheme ?
                            <Image style={toPrintTheme ? stylesLight.image : stylesDark.image} src="/img/whakapapa-for-light-pdf.png" />
                            :
                            <Image style={toPrintTheme ? stylesLight.image : stylesDark.image} src="/img/whakapapa-for-dark-pdf.png" />
                    }
                </View>
                {maternalGrandFather !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={sMaternalGrandFather(maternalGrandFather)[0]}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={sMaternalGrandFather(maternalGrandFather)[1]}
                        />
                    </>
                }
                {maternalGrandMother !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={sMaternalGrandMother(maternalGrandMother)[0]}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={sMaternalGrandMother(maternalGrandMother)[1]}
                        />
                    </>
                }
                {paternalGrandFather !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={sPaternalGrandFather(paternalGrandFather)[0]}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={sPaternalGrandFather(paternalGrandFather)[1]}
                        />
                    </>
                }
                {paternalGrandMother !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={sPaternalGrandMother(paternalGrandMother)[0]}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={sPaternalGrandMother(paternalGrandMother)[1]}
                        />
                    </>
                }
                {mother !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={sMother(mother)[0]}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={sMother(mother)[1]}
                        />
                    </>
                }

                {father !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={sFather(father)[0]}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={sFather(father)[1]}
                        />
                    </>
                }
                {partner !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={sPartner(partner)[0]}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={sPartner(partner)[1]}
                        />
                    </>
                }
                {
                    child !== "" && childrenNumber === "One child" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={sChild(child)[0]}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={sChild(child)[1]}
                        />
                    </>
                }

                {
                    twoChildren[0].name !== "" && childrenNumber === "Two children" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={sTwoChildren(twoChildren)[0]}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={sTwoChildren(twoChildren)[1]}
                        />
                    </>
                }

                {
                    threeChildren[0].name !== "" && childrenNumber === "Three or more children" &&
                    <ThreeChildren inputValueThreeChildren={threeChildren} toPrintTheme={toPrintTheme} />
                }

                
            </View>
        </Page>
    </Document>
);

export default IsMaoriPepehaToPrint;