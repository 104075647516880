import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Title from '../Title'
import Button from '../Button';
import ButtonOption from '../ButtonOption';
import ErrorMessage from '../ErrorMessage';

const ContainerOut = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    text-align: center;
    width: 280px;

    @media only screen and (min-width: 768px) {
        width: 400px;
    }
`;

const ChildrenNumber = ({ state, setState }) => {
    const [showError, setShowError] = useState(false)
    const [view, setView] = useState("")
    useEffect(() => {
        if (view == ""){
            if (state == "None"){
                setView("11")
            }else if (state == "One child"){
                setView("8")
            }else if (state == "Two children"){
                setView("9")
            }else if (state == "Three children"){
                setView("10")
            }
        }
    })
    return (
        <ContainerOut>
            <Container>
                <img src="/svg/child.svg" alt="Children" />
                <Title title="How many children do you have?" />
                <div onClick={() => setView("11")}>
                    <ButtonOption
                        text="None"
                        setBtState={setState}
                        btState="None"
                        active={state === "None"}
                    />
                </div>

                <div onClick={() => setView("8")}>
                    <ButtonOption
                        text="One child"
                        setBtState={setState}
                        btState="One child"
                        active={state === "One child"}
                    />
                </div>

                <div onClick={() => setView("9")}>
                    <ButtonOption
                        text="Two children"
                        setBtState={setState}
                        btState="Two children"
                        active={state === "Two children"}
                    />
                </div>

                <div onClick={() => setView("10")}>
                    <ButtonOption
                        text="Three or more children"
                        setBtState={setState}
                        btState="Three or more children"
                        active={state === "Three or more children"}
                    />
                </div>

                {showError && <ErrorMessage text="Please select an option above" />}

                {
                    view === "" ?
                        <div onClick={() => setShowError(true)}>
                            <Button text="Next" />
                        </div>
                        :
                        <Link to={`/whakapapa/1/${view}`}>
                            <Button text="Next" />
                        </Link>
                }
            </Container>
        </ContainerOut>
    )
}

export default ChildrenNumber;