import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Image from '../components/Image'
import WaiataButtons from '../components/Waiata/WaiataButtons';
import WaiataView from '../components/Waiata/WaiataView';
import LyricOne from '../components/Waiata/LyricOne';
import LyricTwo from '../components/Waiata/LyricTwo';
import LyricThree from '../components/Waiata/LyricThree';
import LyricFour from '../components/Waiata/LyricFour';
import LyricFive from '../components/Waiata/LyricFive';
import LyricSix from '../components/Waiata/LyricSix';
import LyricSeven from '../components/Waiata/LyricSeven';

import Title from '../components/Title'


const WhakapapaContainer = styled.div`
    margin-top: 55px;
`;

const IntroContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const SubTitle = styled.p`
    margin-top: -30px;
`;

const Content = styled.div`
    margin-top: 30px;
`;

const URL = "https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/waiata/"

const Waiata = () => {
    let { view } = useParams()

    useEffect(() => {
        Analytics.record({
            name: "Waiata page visit"
        })
    }, [])

    if (view) {
        return (
            <WhakapapaContainer>
                {
                    view === "0" &&
                    <IntroContainer>
                        <Image image="/img/waiata.jpg" />
                        <Content>
                            <img src="/svg/waiata-icon.svg" />
                            <Title title="Waiata" />
                            {/* <SubTitle>Listen and learn songs</SubTitle> */}
                            <WaiataButtons />
                        </Content>
                    </IntroContainer>
                }

                {
                    view === "1" &&
                    <WaiataView
                        title="Purea nei"
                        // subTitle="Restored by"
                        song={`${URL}purea_nei.mp3`}
                        lyric={<LyricOne />}
                    />
                }

                {/* {
                    view === "2" &&
                    <WaiataView
                        title="Tihei mauri ora"
                        // subTitle="Westpac waiata"
                        song={`${URL}westpac-waiata.mp3`}
                        lyric={<LyricTwo />}
                    />
                } */}

                {
                    view === "3" &&
                    <WaiataView
                        title="Te Aroha"
                        // subTitle="Love"
                        song={`${URL}te-aroha.mp3`}
                        lyric={<LyricThree />}
                    />
                }

                {
                    view === "4" &&
                    <WaiataView
                        title="Tū tira mai ngā iwi"
                        // subTitle="Stand together everyone"
                        song={`${URL}tutira-mai-nga-iwi.mp3`}
                        lyric={<LyricFour />}
                    />
                }
                {
                    view === "5" &&
                    <WaiataView
                        title="Pōkarekare ana"
                        // subTitle="Agitated"
                        song={`${URL}pokarekare-ana.mp3`}
                        lyric={<LyricFive />}
                    />
                }

                {
                    view === "6" &&
                    <WaiataView
                        title="Ka mate"
                        // subTitle="Tis death"
                        song={`${URL}ka-mate.mp3`}
                        lyric={<LyricSix />}
                    />
                }

                {
                    view === "7" &&
                    <WaiataView
                        title="Ehara i te mea"
                        // subTitle="It is not that"
                        song={`${URL}ehara-i-te-mea.mp3`}
                        lyric={<LyricSeven />}
                    />
                }



            </WhakapapaContainer>
        )
    }

}

export default Waiata;