import React from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Menu from './Menu'

const TopBarCoontainer = styled.div`
    background-color: ${props => props.backgroundColor};
    box-shadow: ${props => props.shadow};
    height: 55px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    z-index: 101;
`;

const InsideContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1200px;
`;

const Logo = styled.img`
    margin: 2px 0 0 2px;
    width: 79px;
    height: 46px;
`;

const Back = styled.img`
    cursor: pointer;
    margin: 10px 0 0 20px;
    width: 48px;
    height: 38px;
`;


const TopBar = () => {
    let location = useLocation()
    let path = location.pathname

    let history = useHistory()

    return (
        <TopBarCoontainer
            backgroundColor={path === "/" ? "none" : "white"}
            // shadow={path === "/" ? "none" : "0 4px 8px 0 rgba(0,0,0,0.2)"}
            shadow="none"
        >
            <InsideContainer>
                {path === "/" ?
                    // <Logo src='/svg/logo-white.svg' />
                    <div></div>
                    :
                    <Back onClick={() => history.goBack()} src='/svg/back-top-menu.svg' />
                }

                <Menu />
            </InsideContainer>
        </TopBarCoontainer>
    )
}

export default TopBar