import React, { useState, useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../components/Button';
import Image from '../components/Image'
import SectionIntro from '../components/SectionIntro';
import SectionIntroHandlePopUp from '../components/SectionIntroHandlePopUp';
import PopUpCard from '../components/PopUpCard';
import MihimihiFlow from '../components/mihimihi/MihimihiFlow'

const MihimihiContainer = styled.div`
    margin-top: 55px;
`;

const IntroContainer = styled.div`
    // @media only screen and (min-width: 1200px) {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     height: 90vh;
    // }
`;

const PopUpContent = ({ setShowPopUp }) => {
    return (
        <div>
            <h1>What is mihimihi?</h1>
            <p>
                You are the author of your own mihi. Simply go through each section, choose what you want
                to include, what not to,
                so your mihi suits the situation.
                When you’re finished your mihi
                will be available for you
                to view on this device,
                nō reira, toru, whā,
                KARAWHIUA!’
            </p>
            <div onClick={() => setShowPopUp(false)}>
                <Button text="Tēna, got it" />
            </div>
        </div>
    )
}

const Mihimihi = () => {
    const [showPopUp, setShowPopUp] = useState(false)
    const [toPrintTheme, setToPrintTheme] = useState(true)

    const [greetingsTo, setGreetingsTo] = useState("")
    const [greetingsToCont, setGreetingsToCont] = useState("")
    const [iamFeeling, setIamFeeling] = useState("")
    const [whoHave, setWhoHave] = useState("")
    const [to, setTo] = useState("")
    const [toB, setToB] = useState("")
    const [personalPronoun, setPersonalPronoun] = useState("")
    const [i, setI] = useState("")
    const [therefore, setTherefore] = useState("")

    const [saying, setSaying] = useState("")
    const [great, setGreat] = useState("")
    const [mo, setMo] = useState("")
    const [kite, setKite] = useState("")
    const [these, setThese] = useState("")
    const [concerning, setConcerning] = useState("")


    let { mihimihiView } = useParams()

    useEffect(() => {
        Analytics.record({
            name: "Mihimihi page visit"
        })
    }, [])

    // if (showPopUp) {
    //     return <PopUpCard
    //         setShowPopUp={setShowPopUp}
    //         cardContent={<PopUpContent setShowPopUp={setShowPopUp} />}
    //         withCloseButton={true}
    //     />
    // }

    if (mihimihiView) {
        if (mihimihiView == "0") {
            if (greetingsTo) setGreetingsTo("")
            if (greetingsToCont) setGreetingsToCont("")
            if (iamFeeling) setIamFeeling("")
            if (whoHave) setWhoHave("")
            if (to) setTo("")
            if (toB) setToB("")
            if (personalPronoun) setPersonalPronoun("")
            if (i) setI("")
            if (therefore) setTherefore("")
            if (saying) setSaying("")
            if (great) setGreat("")
            if (mo) setMo("")
            if (kite) setKite("")
            if (these) setThese("")
            if (concerning) setConcerning("")
        }
        return (
            <>
                {showPopUp &&
                    <PopUpCard
                        setShowPopUp={setShowPopUp}
                        cardContent={<PopUpContent setShowPopUp={setShowPopUp} />}
                        withCloseButton={true}
                    />
                }
                <MihimihiContainer>
                    {
                        mihimihiView === "0" &&
                        <IntroContainer>
                            <Image image="/img/mihi.jpg" />
                            < SectionIntro
                                imgSrc="/svg/mihimihi-red.svg"
                                title="Your mihi"
                                toPrintTheme={toPrintTheme}
                                intro={
                                    <SectionIntroHandlePopUp
                                        setShowPopUp={setShowPopUp}
                                        text="Mihi is a speech of greeting, a speech acknowledging a person or a group. Following on from pepeha, it continues to build a relationship with people"
                                    />
                                }
                                btText="Karawhiua - Let's start"
                                path="/mihimihi/1/0.5"

                            />
                        </IntroContainer>
                    }

                    {
                        mihimihiView === "1" &&
                        <MihimihiFlow
                            inputValueGreetingsTo={greetingsTo}
                            setInputStateGreetingsTo={setGreetingsTo}

                            inputValueShowPopUp={showPopUp}
                            setInputStateShowPopUp={setShowPopUp}

                            inputValueToPrintTheme={toPrintTheme}
                            setInputStateToPrintTheme={setToPrintTheme}

                            inputValueGreetingsToCont={greetingsToCont}
                            setInputStateGreetingsToCont={setGreetingsToCont}

                            inputValueIamFeeling={iamFeeling}
                            setInputStateIamFeeling={setIamFeeling}

                            inputValueWhoHave={whoHave}
                            setInputStateWhoHave={setWhoHave}

                            inputValueTo={to}
                            setInputStateTo={setTo}

                            inputValueToB={toB}
                            setInputStateToB={setToB}

                            inputValuePersonalPronoun={personalPronoun}
                            setInputStatePersonalPronoun={setPersonalPronoun}

                            inputValueI={i}
                            setInputStateI={setI}

                            inputValueTherefore={therefore}
                            setInputStateTherefore={setTherefore}


                            inputValueSaying={saying}
                            setInputStateSaying={setSaying}

                            inputValueGreat={great}
                            setInputStateGreat={setGreat}

                            inputValueMo={mo}
                            setInputStateMo={setMo}

                            inputValueKite={kite}
                            setInputStateKite={setKite}

                            inputValueThese={these}
                            setInputStateThese={setThese}

                            inputValueConcerning={concerning}
                            setInputStateConcerning={setConcerning}
                        />
                    }
                </MihimihiContainer>
            </>
        )
    }

}

export default Mihimihi;