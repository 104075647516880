import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;
`;

const Saying = ({
    setInputState,
    inputValue
}) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='He aha te mea nui o tēnei ao, he tangata, he tangata, he tangata.'
                engText="What is the most important thing in this world, it is people."
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "He aha te mea nui o tēnei ao, he tangata, he tangata, he tangata." ? true : false}
            />
            <ButtonChoice
                maoriText="Mā pango mā whero ka oti te mahi."
                engText="Through everyone’s contribution we can achieve anything."
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "Mā pango mā whero ka oti te mahi." ? true : false}
            />
            <ButtonChoice
                maoriText="He wake eke noa."
                engText="We are all in this together."
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "He wake eke noa." ? true : false}
            />
            <ButtonChoice
                maoriText="Tūwhitia te hopo."
                engText="Face your fears and go with it."
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "Tūwhitia te hopo." ? true : false}
            />
            <ButtonChoice
                maoriText="Manuka takato, hikitia ake."
                engText="The challenge laid down, pick it up!"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "Manuka takato, hikitia ake." ? true : false}
            />
        </ButtonContainer>
    )
}

export default Saying;