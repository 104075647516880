import React from 'react';
import { Document, View, Page, Font, Image, Text, StyleSheet } from '@react-pdf/renderer';
import fontMedium from '../../fonts/Poppins-Medium.ttf'
import fontRegular from '../../fonts/Poppins-Regular.ttf'

import {
    whereBorn,
    whereLiving,
    ancestors,
    whanau,
    name,
    mountain,
    hill,
    sea,
    river,
    lake,
    tribe
} from './IsNotMaoriPepehaSentences';

Font.register({ family: 'PoppinsMedium', src: fontMedium })
Font.register({ family: 'PoppinsRegular', src: fontRegular })

// styling guide https://react-pdf.org/styling
const stylesLight = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    section: {
        color: '#2D373C',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        textAlign: 'center',
        margin: 30,
        width: 500
    },
    maori_text: {
        fontSize: 12,
        fontFamily: 'PoppinsMedium'
    },
    eng_text: {
        fontSize: 10,
        fontFamily: 'PoppinsRegular',
        marginBottom: 12,
    },
    image_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 50,
    },
    image: {
        width: 95,
        height: 70
    }
});

const stylesDark = StyleSheet.create({
    page: {
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    section: {
        color: 'white',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        textAlign: 'center',
        margin: 30,
        width: 500
    },
    maori_text: {
        fontSize: 12,
        fontFamily: 'PoppinsMedium'
    },
    eng_text: {
        fontSize: 10,
        fontFamily: 'PoppinsRegular',
        marginBottom: 12,
    },
    image_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 50,
    },
    image: {
        width: 95,
        height: 70
    }
});

const MaoriText = ({ text, toPrintTheme }) => <Text style={toPrintTheme ? stylesLight.maori_text : stylesDark.maori_text}>{text}</Text>
const EngText = ({ text, toPrintTheme }) => <Text style={toPrintTheme ? stylesLight.eng_text : stylesDark.eng_text}>{text}</Text>

const IsNotMaoriPepehaToPrint = ({
    inputValueAncestors,
    inputValueHill,
    inputValueMountain,
    inputValueRiver,
    inputValueLake,
    inputValueSea,
    inputValueTribe,
    inputValueName,
    inputValueWhereBorn,
    inputValueWhereLiving,
    inputValueWhanau,
    toPrintTheme,
    isBasic
}) => (
    <Document>
        <Page size="A4" style={toPrintTheme ? stylesLight.page : stylesDark.page}>
            <View style={toPrintTheme ? stylesLight.section : stylesDark.section}>
                <View style={toPrintTheme ? stylesLight.image_container : stylesDark.image_container}>
                    {
                        toPrintTheme ?
                            <Image style={toPrintTheme ? stylesLight.image : stylesDark.image} src="/img/pepeha-for-light-pdf.png" />
                            :
                            <Image style={toPrintTheme ? stylesLight.image : stylesDark.image} src="/img/pepeha-for-dark-pdf.png" />
                    }

                </View>
                {
                    isBasic == true &&
                    <div>
                        {inputValueAncestors !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={ancestors(inputValueAncestors)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={ancestors(inputValueAncestors)[1]} />
                            </>
                        }
                        {inputValueWhanau !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={whanau(inputValueWhanau)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={whanau(inputValueWhanau)[1]} />
                            </>
                        }
                        {inputValueWhereBorn !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={whereBorn(inputValueWhereBorn)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={whereBorn(inputValueWhereBorn)[1]} />
                            </>
                        }
                        {inputValueWhereLiving !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={whereLiving(inputValueWhereLiving)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={whereLiving(inputValueWhereLiving)[1]} />
                            </>
                        }
                        {inputValueName !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={name(inputValueName)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={name(inputValueName)[1]} />
                            </>
                        }
                    </div>
                }
                {
                    isBasic == false &&
                    <div>
                        {inputValueAncestors !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={ancestors(inputValueAncestors)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={ancestors(inputValueAncestors)[1]} />
                            </>
                        }
                        {inputValueWhanau !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={whanau(inputValueWhanau)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={whanau(inputValueWhanau)[1]} />
                            </>
                        }
                        {inputValueWhereBorn !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={whereBorn(inputValueWhereBorn)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={whereBorn(inputValueWhereBorn)[1]} />
                            </>
                        }
                        {inputValueMountain !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={mountain(inputValueMountain)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={mountain(inputValueMountain)[1]} />
                            </>
                        }
                        {inputValueHill !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={hill(inputValueHill)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={hill(inputValueHill)[1]} />
                            </>
                        }
                        {inputValueLake !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={lake(inputValueLake)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={lake(inputValueLake)[1]} />
                            </>
                        }
                        {inputValueRiver !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={river(inputValueRiver)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={river(inputValueRiver)[1]} />
                            </>
                        }
                        {inputValueSea !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={sea(inputValueSea)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={sea(inputValueSea)[1]} />
                            </>
                        }
                        {inputValueWhereLiving !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={whereLiving(inputValueWhereLiving)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={whereLiving(inputValueWhereLiving)[1]} />
                            </>
                        }
                        {inputValueTribe !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={tribe(inputValueTribe)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={tribe(inputValueTribe)[1]} />
                            </>
                        }
                        {inputValueName !== "" &&
                            <>
                                <MaoriText toPrintTheme={toPrintTheme} text={name(inputValueName)[0]} />
                                <EngText toPrintTheme={toPrintTheme} text={name(inputValueName)[1]} />
                            </>
                        }
                    </div>
                }
            </View>
        </Page>
    </Document>
);

export default IsNotMaoriPepehaToPrint;