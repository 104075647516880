import React from 'react';
import styled from 'styled-components'

const SoundInconStyle = styled.img`
    margin-left: 10px;
    width: 20px;
`;

const SoundIcon = () => <SoundInconStyle src="/svg/sound-icon.svg" />


export default SoundIcon;