import React from 'react';
import styled from 'styled-components'
import AkonaButtons from './AkonaButtons';
import Title from '../Title';

const Container = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const SubTitle = styled.p`
    margin: -30px 0 50px 0;
`;

const AkonaContainer = styled.div`
    background-color: #F8F8F8;
    margin-top: 30px;
    text-align: center;
    padding: 20px 0;

    @media only screen and (min-width: 1200px) {
        width: 530px;
    }
`;

const AkonaView = ({
    title,
    subTitle,
    content
}) => {
    return (

        <Container>
            <div>
                <Title title={title} />
                <SubTitle>{subTitle}</SubTitle>

                <AkonaContainer>
                    {content}
                </AkonaContainer>

                <AkonaButtons />
            </div>
        </Container>

    )
}

export default AkonaView;