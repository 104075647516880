import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Image from '../components/Image'
import AkonaButtons from '../components/Akona/AkonaButtons';
import AkonaView from '../components/Akona/AkonaView';
import Vowels from '../components/Akona/Vowels';
import Syllables from '../components/Akona/Syllables';
import Consonants from '../components/Akona/Consonants';
import Diphthongs from '../components/Akona/Diphthongs';
import Title from '../components/Title'


const WhakapapaContainer = styled.div`
    margin-top: 55px;
`;

const IntroContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const SubTitle = styled.p`
    margin-top: -30px;
`;

const Content = styled.div`
    margin-top: 30px;
`;

const Akona = () => {
    let { view } = useParams()

    useEffect(() => {
        Analytics.record({
            name: "Akona page visit"
        })
    }, [])

    if (view) {
        return (
            <WhakapapaContainer>
                {
                    view === "0" &&
                    <IntroContainer>
                        <Image image="/img/pronounce.jpg" />
                        <Content>
                            <Title title="Whakahua tika" />
                            <SubTitle>Pronunciation </SubTitle>
                            <AkonaButtons />
                        </Content>
                    </IntroContainer>
                }

                {
                    view === "1" &&
                    <AkonaView
                        title="Ngā oropuare"
                        subTitle="Vowels"
                        content={<Vowels />}
                    />
                }

                {
                    view === "2" &&
                    <AkonaView
                        title="Ngā kūoro"
                        subTitle="Syllables"
                        content={<Syllables />}
                    />
                }


                {
                    view === "3" &&
                    <AkonaView
                        title="Ngā orokati"
                        subTitle="Consonats"
                        content={<Consonants />}
                    />
                }

                {
                    view === "4" &&
                    <AkonaView
                        title="Diphthongs"
                        subTitle="Ngā oro honohono"
                        content={<Diphthongs />}
                    />
                }
            </WhakapapaContainer>
        )
    }

}

export default Akona;