import React from 'react';
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import ButtonKarakias from '../ButtonChoiceB';

const OutContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
`;

const ButtonContainer = styled.div`

`;

const KarakiaButtons = () => {
    const { view } = useParams()

    return (
        <OutContainer>
            <ButtonContainer>
                {view !== "1" &&
                    <Link to="/karakia/1">
                        <ButtonKarakias
                            maoriText='Tuia'
                            engText="Karakia - For beginning and Closing"
                        />
                    </Link>
                }
                {view !== "2" &&
                    <Link to="/karakia/2">
                        <ButtonKarakias
                            maoriText='Ūhia mai rā'
                            engText="Karakia - For beginning and Closing"
                        />
                    </Link>
                }

                {view !== "3" &&
                    <Link to="/karakia/3">
                        <ButtonKarakias
                            maoriText='Nau mai (kai)'
                            engText="Before food"
                        />
                    </Link>
                }

                {view !== "4" &&
                    <Link to="/karakia/4">
                        <ButtonKarakias
                            maoriText='Kia tau iho'
                            engText="Karakia - For beginning and Closing"
                        />
                    </Link>
                }

                {view !== "5" &&
                    <Link to="/karakia/5">
                        <ButtonKarakias
                            maoriText='Mauri oho'
                            engText="Karakia - For beginning and Closing"
                        />
                    </Link>
                }

                {view !== "6" &&
                    <Link to="/karakia/6">
                        <ButtonKarakias
                            maoriText='Whakataka te Hau'
                            engText="Karakia - For beginning, starting, opening"
                        />
                    </Link>
                }

                {view !== "7" &&
                    <Link to="/karakia/7">
                        <ButtonKarakias
                            maoriText='Kia tau'
                            engText="Karakia whakamutunga - For closing"
                        />
                    </Link>
                }

                {view !== "8" &&
                    <Link to="/karakia/8">
                        <ButtonKarakias
                            maoriText='Unuhia'
                            engText="Karakia whakamutunga - For closing"
                        />
                    </Link>
                }
            </ButtonContainer>
        </OutContainer>

    )
}

export default KarakiaButtons;