import React from 'react';
import styled from 'styled-components'

const InputStuyled = styled.input`
font-size: 18;
    background: white;
    border: 1px solid #B2B2B2;
    border-radius: 6px;
    color: #2D373C;
    font-family: RegularFont;
    font-size: 18;
    margin: 10px 0;
    padding: 15px;
    width: 250px;

    @media only screen and (min-width: 768px) {
        width: 370px;
    }
`;


const Input = ({ inputValue, setInputState, placeholder }) => {
    return (
        <InputStuyled
            type='text'
            value={inputValue}
            onChange={e => setInputState(e.target.value)}
            placeholder={placeholder}
        />
    )
}

export default Input;