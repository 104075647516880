import React from 'react';
import KarakiaTextGroup from './KarakiaTextGroup'

const LyricEight = () => {

    return (
        <div>
            <KarakiaTextGroup
                textMaori="Unuhia, unuhia"
                textEng="Draw on, draw on"
            />
            <KarakiaTextGroup
                textMaori="Unuhia ki te uru tapu nui"
                textEng="Draw on the supreme sacredness"
            />
            <KarakiaTextGroup
                textMaori="Kia wātea, kia māmā, te ngākau, te tinana, te wairua i te ara takatā"
                textEng="To clear, to free the heart, the body and the spirit of mankind"
            />
            <KarakiaTextGroup
                textMaori="Koia rā e Rongo, whakairia ake ki runga"
                textEng="Rongo, suspended high above us"
            />
            <KarakiaTextGroup
                textMaori="Kia tina! Tina! Hui e! Tāiki e!"
                textEng="Draw together! Affirm!"
            />

        </div>
    )
}

export default LyricEight;