import React from 'react';
import WordContainer from './WordContainer';

const H = ({ active }) => {

    return (
        active === "h" &&
        <div>
            <WordContainer
                text="hapū"
                file="hapu.mp3"
                translation="sub-tribe"
            />
            <WordContainer
                text="hau"
                file="hau.mp3"
                translation="wind"
            />
            <WordContainer
                text="he"
                file="he.mp3"
                translation="a"
            />
            <WordContainer
                text="he hōnore"
                file="he_honore.mp3"
                translation="to honour, Majesty "
            />
            <WordContainer
                text="he tangata"
                file="he_tangata.mp3"
                translation="the people"
            />
            <WordContainer
                text="he whānau"
                file="he_whanau.mp3"
                translation="the family"
            />
            <WordContainer
                text="hoa"
                file="hoa.mp3"
                translation="friend"
                border
            />

        </div>
    )
}

export default H;