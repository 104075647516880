import React from 'react';
import styled from 'styled-components'

const ButtonStyled = styled.button`
    background-color: ${props => props.backgroundColor};
    border: ${props => props.border || "none"};
    border-radius: 6px;
    color: ${props => props.color || "white"};
    cursor: pointer;
    font-family: SemiBoldFont;
    font-size: 14px;
    margin: 10px 0;
    padding: 15px;
    width: 100%;

    :hover {
        background-color: ${props => props.hoverBackgroundColor};
      }
      

    @media only screen and (min-width: 1200px) {
        font-size: 18px;
    }
`;


const Button = ({ text, ArgBackgroundColor, ArgHoverBackgroundColor, ArgBorder, ArgColor }) => {
    return (

        <ButtonStyled
            backgroundColor={ArgBackgroundColor ? ArgBackgroundColor : "#D5002B"}
            hoverBackgroundColor={ArgHoverBackgroundColor ? ArgHoverBackgroundColor : "#9A001F"}
            border={ArgBorder ? ArgBorder : "none"}
            color={ArgColor ? ArgColor : "white"}
        >
            {text}
        </ButtonStyled>

    )
}

export default Button;