import React from 'react';
import WaiataTextGroup from './WaiataTextGroup'


const LyricFour = () => {

    return (
        <div>



            <WaiataTextGroup
                textMaori="Kia tapatahi"
                textEng="Let us unite"
            />



            <WaiataTextGroup
                textMaori="Kia kotahi rā"
                textEng="Let us be one"
            />




            <WaiataTextGroup
                textMaori="Tātou tātou e"
                textEng="We are united"
            />



            <WaiataTextGroup
                textMaori="Tū tira mai ngā iwi (auē!)"
                textEng="Stand together everyone (alas!)"
            />


            <WaiataTextGroup
                textMaori="Tātou tātou e"
                textEng="We are united"
            />


            <WaiataTextGroup
                textMaori="Tū tira mai ngā iwi (auē!)"
                textEng="Stand together everyone (alas!)"
            />


            <WaiataTextGroup
                textMaori="Tātou tātou e"
                textEng="We are united"
            />


            <WaiataTextGroup
                textMaori="Whāia te māramatanga"
                textEng="Seek understanding"
            />


            <WaiataTextGroup
                textMaori="me te aroha e ngā iwi"
                textEng="and also love everyone"
            />


            <WaiataTextGroup
                textMaori="Kia tapatahi"
                textEng="Let us unite"
            />


            <WaiataTextGroup
                textMaori="Kia kotahi rā"
                textEng="Let us be one"
            />


            <WaiataTextGroup
                textMaori="Tātou tātou e"
                textEng="We are united"
            />


            <WaiataTextGroup
                textMaori="Tū tira mai ngā iwi (auē!)"
                textEng="Stand together everyone (alas!)"
            />


            <WaiataTextGroup
                textMaori="Tātou tātou e"
                textEng="We are united"
            />


            <WaiataTextGroup
                textMaori="Tū tira mai ngā iwi (auē!)"
                textEng="Stand together everyone (alas!)"
            />


            <WaiataTextGroup
                textMaori="Tātou tātou e"
                textEng="We are united"
            />


            <WaiataTextGroup
                textMaori="Whāia te māramatanga"
                textEng="Seek understanding"
            />


            <WaiataTextGroup
                textMaori="me te aroha e ngā iwi"
                textEng="and also love everyone"
            />


            <WaiataTextGroup
                textMaori="Kia tapatahi"
                textEng="Let us unite"
            />


            <WaiataTextGroup
                textMaori="Kia kotahi rā"
                textEng="Let us be one"
            />


            <WaiataTextGroup
                textMaori="Tātou tātou e"
                textEng="We are united"
            />


            <WaiataTextGroup
                textMaori="Tātou tātou e"
                textEng="We are united"
            />


            <WaiataTextGroup
                textMaori="Puritia kia mau!"
                textEng="Hold fast and strong!"
            />
            <WaiataTextGroup
                textMaori="Kss hi auē hī!"
                textEng="It is done!"
            />
        </div>

    )
}

export default LyricFour;